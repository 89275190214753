import { Component } from "@angular/core";
import { NavService } from "../../services/nav.service";
import { SearchService } from "../../services/search.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/pages/auth/auth.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

export class HeaderComponent {

  public isFlip: boolean = false;
  public isSearchOpen: boolean = false;
  public open: boolean = false;

  constructor(
    public navService: NavService,
    public searchService: SearchService,
    private router: Router,
    private authService: AuthService
  ) { }

  sidebarToggle() {
    this.navService.collapseSidebar = !this.navService.collapseSidebar;
  }

  logOut() {
    Swal.fire({
      icon : 'warning',
      title : 'Attenzione!',
      text : 'Sei sicuro di voler uscire dal tuo account?',
      showCancelButton: true,
      confirmButtonText: "Conferma",
      cancelButtonText: "Annulla",
      confirmButtonColor: '#0d9a71',
      cancelButtonColor: '#bf1313',
      customClass : {
        cancelButton : 'order-1',
        confirmButton : 'order-2'
      }
    }).then((result) => {
      if(result.isConfirmed){
        this.authService.logout()
      }else{
        Swal.close();
      }
    })
  }

}
