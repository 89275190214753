import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment as env } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AttivitaService {
  public $addressSubject = new BehaviorSubject<any>(null);
  public $provinceSubject = new BehaviorSubject<any>(null);
  private HTTPheaders;
  private options;

  constructor(private http: HttpClient) {
    this.HTTPheaders = {
      "Content-Type": "application/json",
    };
    this.options = {
      headers: this.HTTPheaders,
    };
  }

  getActivitiesList(page?: number, size?: number, filters?: any): Observable<any> {
    const params = new HttpParams({ fromObject: filters });
    return this.http.get<any>(env.baseUrl + "ActivityUser" + "?page=" + page + "&pageResults=" + size + "&" + params, this.options);
  }

  getActivitiesInputs(appType: boolean): Observable<any> {
    const params = new HttpParams().set("noLoader", true);
    return this.http.get<any>(env.baseUrl + "Activity/?page=1&pageResults=100&app=" + appType, { ...this.options, params });
  }

  createUserActivity(data: any): Observable<any> {
    return this.http.post<any>(env.baseUrl + "ActivityUser", data, this.options);
  }

  editUserActivity(data: any): Observable<any> {
    return this.http.put<any>(env.baseUrl + "ActivityUser", data, this.options);
  }

  deleteUserActivity(id: number): Observable<any> {
    return this.http.delete<any>(env.baseUrl + "ActivityUser/" + id);
  }
}
