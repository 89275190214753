<div class="d-flex profile-media align-items-center" (click)="isShow = !isShow">
  <div class="flex-grow-1">
    <span class="profile-name pe-2">{{ name }} {{ surname }}</span>
  </div>
  <div class="role-profile d-flex align-items-center">
    <div class="role-profile_label flex-grow-1">
      <span>{{ role }}</span>
    </div>
    <div class="role-profile_img">
      <img class="w-100" src="assets/images/dashboard/profile.jpeg" alt="" />
    </div>
  </div>
  <!--<ul class="profile-dropdown onhover-show-div" [class.active]="isShow">
    <li>
      <a href="javascript:void(0)"> <app-feather-icons [icons]="'user'"></app-feather-icons>
        <span>Account </span>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)"> <app-feather-icons [icons]="'mail'"></app-feather-icons>
        <span>Inbox</span>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)"> <app-feather-icons [icons]="'file-text'"></app-feather-icons>
        <span>Taskboard</span></a>
    </li>
    <li>
      <a href="javascript:void(0)">
        <app-feather-icons [icons]="'settings'"></app-feather-icons> <span>Settings</span>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)">
        <app-feather-icons [icons]="'log-in'"></app-feather-icons><span>Log in</span>
      </a>
    </li>
  </ul>-->
</div>
