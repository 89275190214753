import { ChangeDetectorRef, Component, EventEmitter, input, Input, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../base-component/base-component.component';
import { NgbCalendar, NgbDate, NgbDatepickerConfig, NgbDateStruct, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, isEmpty, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { AttivitaService } from 'src/app/pages/attività/attivita.service';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrl: './dynamic-input.component.scss',
})
export class DynamicInputComponent extends BaseComponent {
  private subscriptions: Subscription[] = [];
  @Input() inputData: any;
  @Input() activityName: string;
  @Input() userRole: any;
  @Input() user: any;
  @Input() mode: 'nuovo' | 'modifica' | 'visualizza' = 'visualizza';
  @Input() isSaving: any;
  @Output() valueEmitter = new EventEmitter<any>();
  @Output() changeSave = new EventEmitter<any>();
  protected idDoctor: any;
  protected formattedDate: NgbDateStruct;
  protected searchTerm = '';
  protected apiUrl = '';
  protected data: any;
  protected isInvalid: boolean = false;
  protected multiRowData: any[] = [];
  protected bindedLabel: string = 'description';
  protected isDisabled: any;
  protected isInputDisabled: boolean = false;
  protected isMultiRowGroup: boolean = false;
  protected multiGroupData: any[] = [];
  protected multiGroupDataToShow: any[] = [];
  private today = new Date();

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private attivitaService: AttivitaService,
    private calendar: NgbCalendar,
    private config: NgbDatepickerConfig
  ) {
    super();
    const date = new Date();
    config.minDate = { year: date.getFullYear() - 1, month: 1, day: 1 };
    config.maxDate = { year: date.getFullYear() + 1, month: 12, day: 31 };
  }

  override ngOnInit(): void {
    if (this.activityName !== 'Attivita Non Lavorativa') {
      if (this.checkIfConsuntivationUnlocked() && this.userRole.name == 'ISF') {
        this.enableCalendarDates();
      } else this.disableCalendarDates();
    }
    if (Array.isArray(this.inputData)) {
      this.multiGroupData.push(this.inputData);
      this.multiGroupData.forEach((item: any, index: number) => {
        item.forEach((el: any, i: number) => {
          this.initArrayData(el, index, i);
        });
      });
      this.isMultiRowGroup = true;
      return;
    } else if (this.mode === 'visualizza' && this.inputData?.group > 0) {
      this.showMultiGroupData();
      return;
    }
    this.initInputData();
  }

  checkIfConsuntivationUnlocked() {
    const startDate = this.user?.unlockedIsfResponse?.startUnlockDate;
    const endDate = this.user?.unlockedIsfResponse?.endUnlockDate;
    const isUnlcoked = this.user?.unlockedIsfResponse?.unlocked;
    return isUnlcoked && this.today.getTime() >= new Date(startDate).getTime() && this.today.getTime() <= new Date(endDate).getTime();
  }

  disableCalendarDates() {
    this.isDisabled = (date: NgbDateStruct): boolean => {
      const today = this.calendar.getToday();
      const selectedDate = new NgbDate(date.year, date.month, date.day);
      const selectedWeekday = this.calendar.getWeekday(selectedDate);

      const todayJsDate = new Date(today.year, today.month - 1, today.day);

      const mondayOfWeek = new Date(todayJsDate);
      mondayOfWeek.setDate(todayJsDate.getDate() - (todayJsDate.getDay() === 0 ? 6 : todayJsDate.getDay() - 1));

      const selectedJsDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day);

      const mondayOfSelectedWeek = new Date(selectedJsDate);
      mondayOfSelectedWeek.setDate(selectedJsDate.getDate() - (selectedJsDate.getDay() === 0 ? 6 : selectedJsDate.getDay() - 1));

      if (mondayOfSelectedWeek.getTime() !== mondayOfWeek.getTime()) {
        return true;
      }

      const disabledWeekdays = today.day <= 3 ? [4, 5, 6, 7] : [1, 2, 3];
      return disabledWeekdays.includes(selectedWeekday);
    };
  }

  enableCalendarDates() {
    this.isDisabled = undefined;
  }

  getWeekNumber(date: NgbDateStruct): number {
    const d = new Date(date.year, date.month - 1, date.day);
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  }

  showMultiGroupData() {
    if (this.inputData?.value?.length > 0) {
      this.inputData?.value.forEach((el: any, i: number) => {
        el.fullName &&
          this.multiGroupDataToShow.push([
            {
              labelName: 'Medico',
              value: el.fullName,
            },
          ]);
        el.labelNameProduct &&
          this.multiGroupDataToShow.push([
            {
              labelName: 'Saggio Consegnato',
              value: el.labelNameProduct,
            },
          ]);
        el.nameProduct &&
          this.multiGroupDataToShow.push([
            {
              labelName: 'Prodotto',
              value: el.nameProduct,
            },
          ]);
        el.quantity &&
          this.multiGroupDataToShow.push([
            {
              labelName: 'Quantità',
              value: el.quantity,
            },
          ]);

        typeof el.intervista === 'boolean' &&
          this.multiGroupDataToShow.push([
            {
              labelName: 'Intervista',
              value: el.intervista,
            },
          ]);
        typeof el.flash === 'boolean' &&
          this.multiGroupDataToShow.push([
            {
              labelName: 'Flash',
              value: el.flash,
            },
          ]);
      });
    }
  }

  initArrayData(data: any, index: number, i: number) {
    const inputData = this.multiGroupData[index][i];

    switch (inputData.inputType) {
      case 'date':
        const dateValue = inputData.value;
        if (dateValue && dateValue !== '') {
          const date = this.dateFormatService.isoStringToNgbDate(dateValue);
          inputData.formattedDate = this.dateFormatService.formatDate(date, true);
        } else {
          const today = new Date();
          inputData.formattedDate = this.dateFormatService.formatDate(new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate()), true);
          inputData.value = inputData.formattedDate;
        }
        break;

      case 'checkbox':
        inputData.value =
          inputData.value !== '' && inputData.value !== null && inputData.value !== undefined
            ? inputData.value === true || inputData.value === 'True'
            : inputData.defaultValue === true || inputData.defaultValue === 'true';
        break;

      case 'select':
        if (inputData.url) {
          this.selectByUrlMultiGroup(inputData);
        }

        if (inputData.quantityField) {
          inputData.quantity = 0;
        }
        break;
    }
  }

  initInputData() {
    if (this.inputData?.inputType === 'date') {
      const dateValue = this.inputData?.value;
      if (dateValue && dateValue !== '') {
        const date = this.dateFormatService.isoStringToNgbDate(dateValue);
        this.formattedDate = this.dateFormatService.formatDate(date, true);
      } else {
        const today = new Date();
        this.formattedDate = this.dateFormatService.formatDate(new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate()), true);
        this.inputData.value = this.formattedDate;
      }
    }

    if (this.inputData?.inputType === 'checkbox') {
      if (this.inputData?.value !== '' && this.inputData?.value !== null && this.inputData?.value !== undefined) {
        this.inputData.value = this.inputData?.value == true || this.inputData?.value == 'True' ? true : false;
      } else {
        this.inputData.value = this.inputData?.defaultValue == true || this.inputData?.defaultValue == 'true' ? true : false;
      }
    }

    if (this.inputData?.inputType === 'select') {
      if (this.inputData?.url) {
        if (!this.inputData?.data) {
          this.apiUrl = this.inputData?.url;
          this.selectByUrl();
          if (this.inputData?.labelName === 'Utente' && this.inputData?.value) {
            const value = this.inputData?.value;
            const parsedJson = JSON.parse(value);
            this.inputData.value = parsedJson['id'];
          }
        }

        if (this.inputData?.multiRow && this.mode === 'nuovo') {
          this.multiRowData.push({
            name: this.inputData?.labelName,
            value: '',
            mandatory: this.inputData?.mandatory ? true : false,
            multiSelect: this.inputData?.multiSelect,
            inputType: this.inputData?.inputType,
            dataType: this.inputData?.dataType,
            quantity: 0,
            multiRow: this.inputData?.multiRow,
            quantityField: this.inputData?.quantityField,
          });
        } else if (this.inputData?.multiRow && this.mode !== 'nuovo') {
          if (this.inputData?.value?.length > 0) {
            this.inputData.value.forEach((element: any) => {
              this.multiRowData.push({
                name: this.inputData?.labelName,
                value: element?.idProduct,
                mandatory: this.inputData?.mandatory ? true : false,
                multiSelect: this.inputData?.multiSelect,
                inputType: this.inputData?.inputType,
                dataType: this.inputData?.dataType,
                quantity: element?.quantity,
                multiRow: this.inputData?.multiRow,
                quantityField: this.inputData?.quantityField,
              });
            });
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.inputData?.parent === 'Medico Visitato' || this.inputData?.labelName === 'Prodotti Consegnati') {
      this.subscriptions.push(
        this.attivitaService.$addressSubject.subscribe((res) => {
          if (res !== null) {
            if (this.inputData?.parent === 'Medico Visitato') {
              if (this.mode === 'nuovo') {
                this.inputData.value = null;
              }
              this.data = res.addresses;
              this.checkBindedValue();
            } else if (this.inputData?.labelName === 'Prodotti Consegnati' && res?.id !== null && res?.id !== undefined) {
              this.idDoctor = res.id;
              if (this.mode === 'nuovo') {
                this.multiRowData = [];
                this.addRow();
              }

              this.selectByUrl();
            }
          }
        })
      );
    }
    if (
      this.multiGroupData?.length > 0 &&
      this.multiGroupData.some((group: any) =>
        group.some((input: { labelName: string }) => input.labelName === 'Prodotti Consegnati' || input.labelName === 'Saggi Consegnati')
      )
    ) {
      this.subscriptions.push(
        this.attivitaService.$addressSubject.subscribe((res) => {
          if (res !== null) {
            this.idDoctor = res.id;
            const parentIndex = res.parentIndex;
            if (parentIndex !== undefined) {
              this.multiGroupData[parentIndex][1].data = [];
              this.selectByUrlMultiGroup(this.multiGroupData[parentIndex][1]);
            } else {
              const prodottiConsegnati = this.multiGroupData.flat().find((input: { labelName: string }) => input.labelName === 'Prodotti Consegnati');
              if (prodottiConsegnati) {
                prodottiConsegnati.data = [];
                this.selectByUrlMultiGroup(prodottiConsegnati);
              }
            }
          }
        })
      );
    }
    if (this.inputData?.labelName === 'Provincia') {
      this.subscriptions.push(
        this.attivitaService.$provinceSubject.subscribe((res) => {
          if (res !== null) {
            if (this.mode === 'nuovo') {
              this.inputData.value = null;
            }
            this.inputData.value = res[0].province.id;
            this.isInputDisabled = true;
          } else {
            if (this.mode === 'nuovo') {
              this.inputData.value = null;
            }
            this.isInputDisabled = false;
          }
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSaving'] && changes['isSaving']?.currentValue) {
      const value = this.inputData?.value;
      const isValid = value !== null && value !== undefined && value !== '' && (Array.isArray(value) ? value.length > 0 : true);

      if (this.inputData?.mandatory && !isValid) {
        this.isInvalid = true;
      } else {
        if (this.inputData?.inputType === 'date') {
          this.inputData.value = this.dateFormatService.transformStringDate(this.inputData?.value);
        }

        if (this.inputData[0]?.group > 0) {
          this.inputData = this.multiGroupData;
          if (Array.isArray(this.inputData)) {
            for (const item of this.inputData) {
              if (Array.isArray(item)) {
                for (const el of item) {
                  if (el.quantity === 0) {
                    this.toastr.error("La quantità del prodotto dev'essere maggiore di 0", 'Attenzione');
                    return;
                  }
                }
              }
            }
          }
        }
        if (this.multiRowData) {
          for (const item of this.multiRowData) {
            if (item.quantity === 0) {
              this.toastr.error("La quantità del prodotto dev'essere maggiore di 0", 'Attenzione');
              return;
            }
          }
        }
        this.valueEmitter.emit({
          inputData: this.inputData,
          multiRowData: this.multiRowData,
        });
        this.isInvalid = false;
        this.changeSave.emit();
        this.cdr.detectChanges();
      }
    }
    if (changes['userId'] && changes['userId']?.currentValue && !this.inputData?.readOnly && this.mode === 'nuovo') {
      this.inputData.value = null;
    }

    if (changes['activityName'] && changes['activityName']?.currentValue) {
      if (this.activityName !== 'Attivita Non Lavorativa') {
        this.disableCalendarDates();
      } else {
        this.enableCalendarDates();
      }
    }
  }

  onInputChange(event: any, inputType?: string, index?: number): void {
    const value = event?.target?.value ?? event;

    switch (inputType) {
      case 'checkbox':
        this.inputData.value = event.target.checked;
        break;
      case 'select':
        this.inputData.value = value;
        this.isInvalid = Array.isArray(value) ? value.length === 0 : !value;
        if (this.inputData?.multiRow && index !== undefined) {
          this.multiRowData[index].value = value !== null && value !== undefined && value !== '' ? value : null;
        }
        if (this.inputData?.labelName === 'Medico Visitato') {
          this.onDoctorChange(value);
        }
        if (this.inputData?.labelName === 'Indirizzo Di Visita') {
          this.onAddressChange(this.data, value);
        }
        break;
      default:
        if (inputType === 'quantity' && index !== undefined) {
          this.multiRowData[index].quantity = +value;
          break;
        }
        if (!isNaN(value) && this.inputData?.dataType === 'Int') {
          this.inputData.value = +value;
          this.isInvalid = !value;
        } else {
          this.inputData.value = value ?? 0;
          this.isInvalid = !value;
        }
    }
  }

  onInputMultiGroupChange(event: any, inputType: string, parentIndex: number, index: number, inputData?: any) {
    const value = event?.target?.value ?? event;
    if (inputType === 'checkbox') {
      this.multiGroupData[parentIndex][index].value = event.target.checked;
    } else if (inputType !== 'quantity') {
      this.multiGroupData[parentIndex][index].value = value;
      this.isInvalid = Array.isArray(value) ? value.length === 0 : !value;
    } else this.multiGroupData[parentIndex][index].quantity = +value;
    if (inputData?.labelName === 'Medici') {
      this.onDoctorGroupChange(value, parentIndex, index);
    }
  }

  onAddressChange(address: any, currentValue?: any) {
    this.attivitaService.$provinceSubject.next(currentValue ? address : null);
  }

  onDoctorGroupChange(doctorId: number, parentIndex: number, index: number) {
    const selectedDoctor = this.multiGroupData[parentIndex][index].data.filter((doctor: any) => doctor.id == doctorId)[0];
    this.attivitaService.$addressSubject.next({
      id: doctorId,
      addresses: selectedDoctor?.addresses,
      profile: selectedDoctor?.profile,
      speciality: selectedDoctor?.speciality,
      parentIndex: parentIndex,
      index: index,
    });
  }

  onDoctorChange(doctorId: number) {
    const selectedDoctor = this.data.filter((doctor: any) => doctor.id == doctorId)[0];
    this.attivitaService.$addressSubject.next({
      id: doctorId,
      addresses: selectedDoctor?.addresses,
      profile: selectedDoctor?.profile,
      speciality: selectedDoctor?.speciality,
    });
    this.attivitaService.$provinceSubject.next(null);
  }

  clickDoctorInfo(doctorId: number) {
    if (doctorId !== null && doctorId !== undefined && !isNaN(doctorId)) {
      this.router.navigate(['/anagrafiche/medici/visualizza/' + doctorId]);
    }
  }

  onSearch(event: any) {
    this.searchTerm = event.term;
    const searchTerm$ = new Observable<string>((observer) => {
      observer.next(event.term);
      observer.complete();
    })
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (event.term.length > 3 || event.term.length === 0) {
          this.selectByUrl();
        }
      });
  }

  addRow() {
    this.multiRowData.push({
      name: this.inputData?.labelName,
      value: '',
      mandatory: this.inputData?.mandatory,
      multiSelect: this.inputData?.multiSelect,
      inputType: this.inputData?.inputType,
      dataType: this.inputData?.dataType,
      multiRow: this.inputData?.multiRow,
      quantityField: this.inputData?.quantityField,
      quantity: 0,
    });
  }

  removeRow(index: number) {
    this.multiRowData.splice(index, 1);
  }

  addMultiGroupRow() {
    const newRow = this.multiGroupData[0].map((el: any) => ({
      ...el,
      value: '',
      quantity: el.quantityField ? 0 : el.quantity,
    }));
    this.multiGroupData.push(newRow);
  }

  removeMultiGroupRow(index: number) {
    this.multiGroupData.splice(index, 1);
  }

  selectByUrlMultiGroup(inputData: any) {
    if (!inputData.data || inputData.data.length === 0) {
      const apiUrl = inputData.url;
      let url: any;
      if (inputData?.labelName === 'Medici') {
        url = new URL(env.baseUrl + apiUrl + `&isf=${this.user.id}`);
      } else if (inputData?.labelName === 'Saggi Consegnati' || inputData?.labelName === 'Prodotti Consegnati') {
        if (this.idDoctor !== null && this.idDoctor !== undefined) {
          url = new URL(env.baseUrl + apiUrl + `${this.idDoctor}&idUser=${this.user.id}`);
        } else return;
      } else {
        if (apiUrl) {
          url = new URL(env.baseUrl + apiUrl);
        } else return;
      }
      if ((this.userRole?.name === 'ISF' || this.userRole?.name === 'SP') && inputData.labelName === 'Medico Visitato') {
        url.searchParams.set(this.userRole?.name.toLowerCase(), this.user.id);
      }
      if (this.searchTerm) {
        url.searchParams.set('name', this.searchTerm);
      }
      this.http.get<any>(url.toString()).subscribe({
        next: (res) => {
          if (inputData.labelName === 'Prodotti Consegnati' && inputData.autoFill && inputData.repeater > 0) {
            this.fillProdottiConsegnati(res.data?.list, inputData, inputData.repeater);
          }

          inputData.data = res.data?.list ? res.data?.list : res.data;
          if (inputData.labelName === 'Medico Visitato') {
            this.onInputChange(inputData.value, 'select');
          }
          this.checkArrayBindedValue(inputData);
        },
      });
    }
  }

  fillProdottiConsegnati(data: any, inputData: any, repeater: number) {
    if (!data?.length) return;
    const baseRow = JSON.parse(JSON.stringify(this.multiGroupData[0]));
    this.multiGroupData = [];

    data.forEach((item: any, index: number) => {
      const rowToAdd = JSON.parse(JSON.stringify(baseRow));
      rowToAdd.forEach((field: any) => {
        if (field.labelName === 'Flash') {
          field.value = item.flash;
        }
        if (field.labelName === 'Intervista') {
          field.value = item.intervista;
        }
        if (field.labelName === 'Prodotti Consegnati') {
          field.data = data;
          field.value = item.id;
          field.quantity = item?.wiseAverangeNumber ?? 0;
          if (item.hasOwnProperty('name')) {
            field.bindedLabel = 'name';
          } else if (item.hasOwnProperty('fullName')) {
            field.bindedLabel = 'fullName';
          } else if (item.hasOwnProperty('labelName')) {
            field.bindedLabel = 'labelName';
          } else if (item.hasOwnProperty('description')) {
            field.bindedLabel = 'description';
          }
        }
      });
      if (index < repeater) {
        this.multiGroupData.push(rowToAdd);
      }
    });

    this.multiGroupData.forEach((row) => {
      row.forEach((field: any) => {
        if (field.data && field.data.length > 0) {
          this.checkArrayBindedValue(field);
        }
      });
    });
  }

  selectByUrl() {
    const apiUrl = this.inputData?.url;
    let url: any;
    if (this.inputData?.labelName === 'Medici') {
      url = new URL(env.baseUrl + apiUrl + `&isf=${this.user.id}`);
    } else if (this.inputData?.labelName === 'Prodotti Consegnati') {
      if (this.idDoctor !== null && this.idDoctor !== undefined) {
        url = new URL(env.baseUrl + apiUrl + `${this.idDoctor}&idUser=${this.user.id}`);
      } else return;
    } else {
      url = new URL(env.baseUrl + apiUrl);
    }
    if ((this.userRole?.name === 'ISF' || this.userRole?.name === 'SP') && this.inputData?.labelName === 'Medico Visitato') {
      url.searchParams.set(this.userRole?.name.toLowerCase(), this.user.id);
    }
    if (this.searchTerm) {
      url.searchParams.set('name', this.searchTerm);
    }
    return this.http.get<any>(url.toString()).subscribe({
      next: (res) => {
        this.data = res.data?.list ? res.data?.list : res.data;
        if (this.inputData?.labelName === 'Medico Visitato') {
          this.onInputChange(this.inputData?.value, 'select');
        }
        this.checkBindedValue();
      },
    });
  }

  checkBindedValue() {
    switch (true) {
      case this.data && this.data[0]?.hasOwnProperty('abbreviationProvince'):
        this.bindedLabel = 'abbreviationProvince';
        this.data = this.data.sort((a: { abbreviationProvince: string }, b: { abbreviationProvince: any }) =>
          a.abbreviationProvince.localeCompare(b.abbreviationProvince)
        );
        break;
      case this.data && this.data[0]?.hasOwnProperty('businessName'):
        this.bindedLabel = 'businessName';
        break;
      case this.data && this.data[0]?.hasOwnProperty('address'):
        this.bindedLabel = 'address';
        break;
      case this.data && this.data[0]?.hasOwnProperty('fullName'):
        this.bindedLabel = 'fullName';
        this.data = this.data.sort((a: { fullName: string }, b: { fullName: any }) => a.fullName.localeCompare(b.fullName));
        break;
      case this.data && this.data[0]?.hasOwnProperty('surname'):
        this.data = this.data.map((item: any) => {
          return {
            ...item,
            fullName: item.name + ' ' + item.surname,
          };
        });
        this.bindedLabel = 'fullName';
        break;
      default:
        this.bindedLabel = 'name';
    }
  }

  checkArrayBindedValue(inputData: any) {
    switch (true) {
      case inputData && inputData.data[0]?.hasOwnProperty('abbreviationProvince'):
        inputData.bindedLabel = 'abbreviationProvince';
        inputData.data = inputData.data.sort((a: { abbreviationProvince: string }, b: { abbreviationProvince: any }) =>
          a.abbreviationProvince.localeCompare(b.abbreviationProvince)
        );
        break;
      case inputData && inputData.data[0]?.hasOwnProperty('businessName'):
        inputData.bindedLabel = 'businessName';
        break;
      case inputData && inputData.data[0]?.hasOwnProperty('address'):
        inputData.bindedLabel = 'address';
        break;
      case inputData && inputData.data[0]?.hasOwnProperty('fullName'):
        inputData.bindedLabel = 'fullName';
        inputData.data = inputData.data.sort((a: { fullName: string }, b: { fullName: any }) => a.fullName.localeCompare(b.fullName));
        break;
      case inputData && inputData.data[0]?.hasOwnProperty('surname'):
        inputData.data = inputData.data.map((item: any) => {
          return {
            ...item,
            fullName: item.labelName + ' ' + item.surname,
          };
        });
        inputData.bindedLabel = 'fullName';
        break;
      default:
        inputData.bindedLabel = 'name';
    }
  }

  onDateChange(date: NgbDate) {
    this.formattedDate = this.dateFormatService.formatDate(date, true);
    this.inputData.value = this.formattedDate;
    this.isInvalid = false;
  }

  returnType(value: any) {
    return typeof value;
  }

  override ngOnDestroy(): void {
    this.attivitaService.$addressSubject.next(null);
    this.attivitaService.$provinceSubject.next(null);
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
