import { Component, OnInit } from '@angular/core';
import {LayoutService} from "../../services/layout.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent {

  public show: boolean = true;

  isLoading$ = this.loaderService.loading$;

  constructor(
      private loaderService: LayoutService,
  ) {
    setTimeout(() => {
      this.show = false;  
    }, 3000);
  }

  ngOnInit(): void { }


}
