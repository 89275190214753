<div>
  <div class="logo-wrapper">
    <a href="javascript:void(0)">
      <img src="assets/images/logo/logo_alfaforce_small.svg" alt="">
    </a>
    <div class="back-btn" (click)="sidebarToggle()">
      <i class="fa fa-angle-left"></i>
    </div>
  </div>
  <div class="logo-icon-wrapper">
    <a href="javascript:void(0)">
      <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt=""></a>
  </div>
  <div class="wrap-icon-toggle-menu">
      <div class="" (click)="sidebarToggle()">
          <img alt="" class="icon-sidebar" [ngClass]="{'active' : !activePin}" src="../../../../assets/svg/toggle-icon.svg">
      </div>
  </div>
  <nav class="sidebar-main">
    <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
      <app-feather-icons [icons]="'arrow-left'"></app-feather-icons>
    </div>
    <div id="sidebar-menu" class="sidebar-custom-scroll" 
    [ngStyle]="{ marginLeft: this.layoutService.config.settings.sidebar_type == 'horizontal-wrapper' ? margin + 'px' : '0px' }">
        <ul class="sidebar-links" id="simple-bar">
        <li class="back-btn">
          <a href="javascript:void(0)">
            <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="">
          </a>
          <div class="mobile-back text-end" (click)="sidebarToggle()">
            <span>Back</span>
            <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
          </div>
        </li>
        <!--<li class="pin-title sidebar-main-title" [class.show]="pinnedData">
          <div>
            <h6>Pinned</h6>
          </div>
        </li>-->
          <ng-container *ngTemplateOutlet="recursiveMenu; context: { $implicit: menuItemsList }"></ng-container>
          <ng-template #recursiveMenu let-menuItemsList> 
            <ng-container *ngFor="let item of menuItemsList">
              <li [class.pined]="isPined(item.title)" [ngClass]="{'sidebar-list':item.level == 1 ,'sidebar-main-title': item.headTitle1}">
                <!--<div *ngIf="item.headTitle1">
                  <h6>{{item.headTitle1 | translate}}</h6>
                </div>
                <i *ngIf="item.level == 1" class="fa fa-thumb-tack" (click)="pinned(item.title)"></i>-->
                <a *ngIf="item.type === 'extTabLink'; else link" href="{{ !item.type ? null : item.path }}" [class]="item.linkDisabled">
                  {{ item.title | translate }}
                </a>
               <ng-template #link>  
                <a *ngIf="!item.headTitle1" 
                   [ngClass]="{ 'sidebar-link sidebar-title' : item.level == 1, 'submenu-title' : item.level == 2 , 'active' : item.active && (item.level == 1 || item.level == 2) }"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [class.link-nav]="!item.children &&  item.level == 1"  [routerLink]="item.path" (click)="toggletNavActive(item)">
                   <app-svg-icon *ngIf="item.icon &&  item.level == 1" [icon]="item.icon"></app-svg-icon>
                  <span *ngIf="item.level == 1; else noData">{{item.title| translate}}</span>
              
                  <ng-template #noData>
                    {{item.title | translate}} <br>
                    {{item.subtitle ? ( item.subtitle | translate) : " " }}
                  </ng-template>
                  <div class="according-menu">
                    <!--<i class="fa fa-angle-{{ item.active ? 'down' : 'right' }} pull-right" *ngIf="item.children || item.level == 2"></i>-->
                      <svg fill="#9099a2" width="64px" height="64px" viewBox="-8.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#9099a2" *ngIf="(item.children || item.level == 2) && item.active"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>angle-down</title> <path d="M7.28 20.040c-0.24 0-0.44-0.080-0.6-0.24l-6.44-6.44c-0.32-0.32-0.32-0.84 0-1.2 0.32-0.32 0.84-0.32 1.2 0l5.84 5.84 5.84-5.84c0.32-0.32 0.84-0.32 1.2 0 0.32 0.32 0.32 0.84 0 1.2l-6.44 6.44c-0.16 0.16-0.4 0.24-0.6 0.24z"></path> </g></svg>
                      <svg fill="#9099a2" width="64px" height="64px" viewBox="-8.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"  *ngIf="(item.children || item.level == 2) && !item.active"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>angle-top</title> <path d="M0.84 20.040c-0.2 0-0.44-0.080-0.6-0.24-0.32-0.32-0.32-0.84 0-1.2l6.44-6.44c0.32-0.32 0.88-0.32 1.2 0l6.44 6.44c0.32 0.32 0.32 0.84 0 1.2-0.32 0.32-0.84 0.32-1.2 0l-5.84-5.84-5.84 5.84c-0.16 0.16-0.4 0.24-0.6 0.24z"></path> </g></svg>
                  </div>
                </a>
               </ng-template>
                <ul [ngClass]="{ 'sidebar-submenu': item.level == 1, 'nav-sub-childmenu submenu-content' : item.level == 2}" *ngIf="item.children" [style.display]=" item.active ? 'block' : 'none'">
                  <ng-container *ngTemplateOutlet="recursiveMenu; context: { $implicit: item.children }"></ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-template>
      </ul>
    </div>
    <div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
      <app-feather-icons [icons]="'arrow-right'"></app-feather-icons>
    </div>
  </nav>
</div>
