import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment as env } from "../../../environments/environment";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TipologicheService {
  private HTTPheaders;
  private options;

  constructor(private http: HttpClient) {
    this.HTTPheaders = {
      "Content-Type": "application/json",
    };
    this.options = {
      headers: this.HTTPheaders,
    };
  }

  getTypeTypology(idTypology?: any): Observable<any> {
    // https://<host>/api/<version>/Typology/Type
    return this.http.get<any>(env.baseUrl + "Typology/Facility" + (idTypology ? "?idTypeTypology=" + idTypology : "")); // all type
  }

  getSurgeryTypology(): Observable<any> {
    return this.http.get<any>(env.baseUrl + "Typology/Type"); // Tipo tipologica
  }

  getTypologyId(idTypology: any): Observable<any> {
    return this.http.get<any>(env.baseUrl + `Typology/?idTypeTypology=${idTypology}`); // all type
  }

  deleteTipology(id: number): Observable<any> {
    return this.http.delete<any>(env.baseUrl + `Typology/${id}`);
  }

  storeTypology(typology: any): Observable<any> {
    return this.http.post<any>(env.baseUrl + `Typology`, typology);
  }

  updateTypology(typology: any): Observable<any> {
    return this.http.put<any>(env.baseUrl + `Typology`, typology);
  }

  getMacroCategories(): Observable<any> {
    return this.http.get<any>(env.baseUrl + "Typology/MacroCategory");
  }
}
