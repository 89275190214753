<form
  class="card theme-form"
  novalidate
  [ngClass]="{ 'was-validated': validate }"
  [ngClass]="{ viewMode: mode === 'visualizza' }"
  [formGroup]="profiloForm"
  (ngSubmit)="onSubmit()">
  <div class="card-header">
    <div class="d-flex justify-content-end">
      <button class="btn small-square-primary me-2 button-icon" type="submit" *ngIf="mode === 'modifica'" (click)="goBack()">
        <img src="./../../../../assets/svg/edit.svg" alt="" />
      </button>
      <button class="btn small-square-primary me-2" type="submit" *ngIf="mode === 'modifica'" (click)="goBack()">
        <i class="icofont icofont-arrow-left"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 gy-4">
        <div class="form-group">
          <label class="form-label" for="name">Nome</label>
          <input class="form-control" formControlName="name" id="name" type="text" required="" />
          <div class="invalid-feedback">Campo obbligatorio</div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 gy-4">
        <div class="form-group">
          <label class="form-label" for="surname">Cognome</label>
          <input class="form-control" formControlName="surname" id="surname" type="text" required="" />
          <div class="invalid-feedback">Campo obbligatorio</div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 gy-4">
        <div class="form-group">
          <label class="form-label">Email</label>
          <input class="form-control" type="email" formControlName="email" required="" />
          <div class="invalid-feedback">Campo obbligatorio</div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 gy-4">
        <div class="form-group">
          <label class="form-label" for="role">Ruolo</label>
          <input class="form-control" formControlName="role" id="role" type="text" required="" />
          <div class="invalid-feedback">Campo obbligatorio</div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 gy-4" *ngIf="user?.productLinee !== null">
        <label class="form-label" for="productLinee">Linea di Prodotto <sup *ngIf="mode != 'visualizza'">*</sup></label>
        <ng-select formControlName="productLinee" bindLabel="name" bindValue="id" [multiple]="true" [closeOnSelect]="false" id="productLinee">
          <ng-option *ngFor="let item of productLinee" [value]="item.id">{{ item.name }}</ng-option>
        </ng-select>
        <div class="invalid-feedback">Campo obbligatorio</div>
      </div>
      <div class="col-12 d-flex justify-content-end gy-5">
        <button class="btn btn-primary" type="button" (click)="openStaticModal(staticContent)">Modifica password</button>
      </div>
    </div>
  </div>
</form>

<ng-template #staticContent let-modal>
  <div class="modal-body text-start">
    <div class="modal-body-content p-5">
      <div class="modal-toggle-wrapper">
        <h4 class="text-dark">Modifica Password</h4>
        <p>Compila i campi sottostanti per aggiornare la tua password.</p>
        <form #f="ngForm" [formGroup]="changePasswordForm" class="theme-form">
          <div class="content">
            <div class="form-group mt-4">
              <label class="form-label" for="oldPassword">VECCHIA PASSWORD <sup>*</sup></label>
              <div class="position-relative">
                <input
                  class="form-control"
                  formControlName="oldPassword"
                  id="oldPassword"
                  placeholder="*********"
                  [type]="show ? 'text' : 'password'"
                  (focus)="(false)" />
                <div class="show-hide">
                  <span (click)="show = !show" [class.show]="show">
                    <!-- hide icon -->
                    <svg *ngIf="!show" xmlns="http://www.w3.org/2000/svg" width="45.001" height="36.001" viewBox="0 0 45.001 36.001">
                      <path
                        d="M2.728.359A1.688,1.688,0,1,0,.647,3.016L42.272,35.641a1.688,1.688,0,0,0,2.081-2.658l-7.4-5.794a28.014,28.014,0,0,0,5.618-8.325,2.236,2.236,0,0,0,0-1.73,28,28,0,0,0-6.539-9.218A19.754,19.754,0,0,0,22.5,2.25,19.2,19.2,0,0,0,10.6,6.525ZM15.687,10.512A10.127,10.127,0,0,1,31.4,22.83l-2.714-2.123a6.749,6.749,0,0,0-5.892-9.45.6.6,0,0,0-.52.823,4.565,4.565,0,0,1,.232,1.427,4.479,4.479,0,0,1-.464,1.99l-6.349-4.978Zm10.54,16.9A10.126,10.126,0,0,1,12.375,18a10.472,10.472,0,0,1,.1-1.42l-6.63-5.224a26.6,26.6,0,0,0-3.417,5.78,2.236,2.236,0,0,0,0,1.73,28,28,0,0,0,6.539,9.218A19.754,19.754,0,0,0,22.5,33.75a18.483,18.483,0,0,0,8.873-2.285Z"
                        transform="translate(0 0)" />
                    </svg>
                    <!-- show icon -->
                    <svg *ngIf="show" xmlns="http://www.w3.org/2000/svg" width="40.504" height="31.5" viewBox="0 0 40.504 31.5">
                      <path
                        d="M20.25,2.25A19.785,19.785,0,0,0,6.708,7.917,27.859,27.859,0,0,0,.176,17.135a2.236,2.236,0,0,0,0,1.73,27.859,27.859,0,0,0,6.532,9.218A19.785,19.785,0,0,0,20.25,33.75a19.785,19.785,0,0,0,13.542-5.667,28.071,28.071,0,0,0,6.539-9.218,2.236,2.236,0,0,0,0-1.73,28,28,0,0,0-6.539-9.218A19.785,19.785,0,0,0,20.25,2.25ZM10.125,18A10.125,10.125,0,1,1,20.25,28.125,10.125,10.125,0,0,1,10.125,18ZM20.25,13.5a4.5,4.5,0,0,1-4.5,4.5,4.565,4.565,0,0,1-1.427-.232.6.6,0,0,0-.823.52,6.523,6.523,0,0,0,.225,1.455,6.751,6.751,0,1,0,6.813-8.494.6.6,0,0,0-.52.823A4.565,4.565,0,0,1,20.25,13.5Z"
                        transform="translate(-0.002 -2.25)" />
                    </svg>
                  </span>
                </div>
              </div>
              @if (changePasswordForm.controls['oldPassword'].touched && changePasswordForm.controls['oldPassword'].errors?.['required']) {
              <div class="text text-danger mt-1">Campo obbligatorio</div>
              }
            </div>
            <div class="form-group mt-4">
              <label class="form-label" for="password">PASSWORD <sup>*</sup></label>
              <div class="position-relative">
                <input
                  class="form-control"
                  formControlName="password"
                  id="password"
                  placeholder="*********"
                  [type]="show2 ? 'text' : 'password'"
                  validateEqual="confirmPassword"
                  reverse="true" />
                <div class="show-hide">
                  <span (click)="show2 = !show2" [class.show]="show2">
                    <!-- hide icon -->
                    <svg *ngIf="!show2" xmlns="http://www.w3.org/2000/svg" width="45.001" height="36.001" viewBox="0 0 45.001 36.001">
                      <path
                        d="M2.728.359A1.688,1.688,0,1,0,.647,3.016L42.272,35.641a1.688,1.688,0,0,0,2.081-2.658l-7.4-5.794a28.014,28.014,0,0,0,5.618-8.325,2.236,2.236,0,0,0,0-1.73,28,28,0,0,0-6.539-9.218A19.754,19.754,0,0,0,22.5,2.25,19.2,19.2,0,0,0,10.6,6.525ZM15.687,10.512A10.127,10.127,0,0,1,31.4,22.83l-2.714-2.123a6.749,6.749,0,0,0-5.892-9.45.6.6,0,0,0-.52.823,4.565,4.565,0,0,1,.232,1.427,4.479,4.479,0,0,1-.464,1.99l-6.349-4.978Zm10.54,16.9A10.126,10.126,0,0,1,12.375,18a10.472,10.472,0,0,1,.1-1.42l-6.63-5.224a26.6,26.6,0,0,0-3.417,5.78,2.236,2.236,0,0,0,0,1.73,28,28,0,0,0,6.539,9.218A19.754,19.754,0,0,0,22.5,33.75a18.483,18.483,0,0,0,8.873-2.285Z"
                        transform="translate(0 0)" />
                    </svg>
                    <!-- show icon -->
                    <svg *ngIf="show2" xmlns="http://www.w3.org/2000/svg" width="40.504" height="31.5" viewBox="0 0 40.504 31.5">
                      <path
                        d="M20.25,2.25A19.785,19.785,0,0,0,6.708,7.917,27.859,27.859,0,0,0,.176,17.135a2.236,2.236,0,0,0,0,1.73,27.859,27.859,0,0,0,6.532,9.218A19.785,19.785,0,0,0,20.25,33.75a19.785,19.785,0,0,0,13.542-5.667,28.071,28.071,0,0,0,6.539-9.218,2.236,2.236,0,0,0,0-1.73,28,28,0,0,0-6.539-9.218A19.785,19.785,0,0,0,20.25,2.25ZM10.125,18A10.125,10.125,0,1,1,20.25,28.125,10.125,10.125,0,0,1,10.125,18ZM20.25,13.5a4.5,4.5,0,0,1-4.5,4.5,4.565,4.565,0,0,1-1.427-.232.6.6,0,0,0-.823.52,6.523,6.523,0,0,0,.225,1.455,6.751,6.751,0,1,0,6.813-8.494.6.6,0,0,0-.52.823A4.565,4.565,0,0,1,20.25,13.5Z"
                        transform="translate(-0.002 -2.25)" />
                    </svg>
                  </span>
                </div>
              </div>
              @if (changePasswordForm.controls['password'].touched && changePasswordForm.controls['password'].errors?.['required']) {
              <div class="text text-danger mt-1">Campo obbligatorio</div>
              } @if ( changePasswordForm.controls['password'].errors?.['hasNumber']) {
              <div class="text text-danger mt-1">La password deve contenere almeno un numero</div>
              } @if ( changePasswordForm.controls['password'].errors?.['hasSpecialChar']) {
              <div class="text text-danger mt-1">La password deve contenere almeno un carattere speciale</div>
              } @if ( changePasswordForm.controls['password'].errors?.['isValidLength']) {
              <div class="text text-danger mt-1">La password deve essere lunga almeno 6 caratteri</div>
              } @if ( changePasswordForm.controls['password'].errors?.['hasUpperCase']) {
              <div class="text text-danger mt-1">La password deve contenere almeno una lettera maiuscola</div>
              }
            </div>
            <div class="form-group mt-4">
              <label class="form-label" for="confirmPassword">CONFERMA PASSWORD <sup>*</sup></label>
              <div class="position-relative">
                <input
                  class="form-control"
                  formControlName="confirmPassword"
                  id="confirmPassword"
                  placeholder="*********"
                  [type]="show3 ? 'text' : 'password'"
                  validateEqual="password"
                  reverse="false" />
                <div class="show-hide">
                  <span (click)="show3 = !show3" [class.show]="show3">
                    <!-- hide icon -->
                    <svg *ngIf="!show3" xmlns="http://www.w3.org/2000/svg" width="45.001" height="36.001" viewBox="0 0 45.001 36.001">
                      <path
                        d="M2.728.359A1.688,1.688,0,1,0,.647,3.016L42.272,35.641a1.688,1.688,0,0,0,2.081-2.658l-7.4-5.794a28.014,28.014,0,0,0,5.618-8.325,2.236,2.236,0,0,0,0-1.73,28,28,0,0,0-6.539-9.218A19.754,19.754,0,0,0,22.5,2.25,19.2,19.2,0,0,0,10.6,6.525ZM15.687,10.512A10.127,10.127,0,0,1,31.4,22.83l-2.714-2.123a6.749,6.749,0,0,0-5.892-9.45.6.6,0,0,0-.52.823,4.565,4.565,0,0,1,.232,1.427,4.479,4.479,0,0,1-.464,1.99l-6.349-4.978Zm10.54,16.9A10.126,10.126,0,0,1,12.375,18a10.472,10.472,0,0,1,.1-1.42l-6.63-5.224a26.6,26.6,0,0,0-3.417,5.78,2.236,2.236,0,0,0,0,1.73,28,28,0,0,0,6.539,9.218A19.754,19.754,0,0,0,22.5,33.75a18.483,18.483,0,0,0,8.873-2.285Z"
                        transform="translate(0 0)" />
                    </svg>
                    <!-- show icon -->
                    <svg *ngIf="show3" xmlns="http://www.w3.org/2000/svg" width="40.504" height="31.5" viewBox="0 0 40.504 31.5">
                      <path
                        d="M20.25,2.25A19.785,19.785,0,0,0,6.708,7.917,27.859,27.859,0,0,0,.176,17.135a2.236,2.236,0,0,0,0,1.73,27.859,27.859,0,0,0,6.532,9.218A19.785,19.785,0,0,0,20.25,33.75a19.785,19.785,0,0,0,13.542-5.667,28.071,28.071,0,0,0,6.539-9.218,2.236,2.236,0,0,0,0-1.73,28,28,0,0,0-6.539-9.218A19.785,19.785,0,0,0,20.25,2.25ZM10.125,18A10.125,10.125,0,1,1,20.25,28.125,10.125,10.125,0,0,1,10.125,18ZM20.25,13.5a4.5,4.5,0,0,1-4.5,4.5,4.565,4.565,0,0,1-1.427-.232.6.6,0,0,0-.823.52,6.523,6.523,0,0,0,.225,1.455,6.751,6.751,0,1,0,6.813-8.494.6.6,0,0,0-.52.823A4.565,4.565,0,0,1,20.25,13.5Z"
                        transform="translate(-0.002 -2.25)" />
                    </svg>
                  </span>
                </div>
              </div>
              @if (changePasswordForm.controls['confirmPassword'].touched && changePasswordForm.controls['password'].errors?.['required']) {
              <div class="text text-danger mt-1">Campo obbligatorio</div>
              } @if ( changePasswordForm.controls['confirmPassword'].errors?.['passwordMismatch']) {
              <div class="text text-danger mt-1">Password non corrispondente</div>
              }
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-center mt-5">
          <button type="submit" class="btn btn-primary btn-fill" (click)="saveNewPassword()" [disabled]="changePasswordForm.invalid">Salva</button>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="d-flex footer-sweetalert align-items-center overflow-hidden">
      <div class="col d-flex align-items-baseline justify-content-center">
        <img src="./../../../../assets/svg/alfa-force-logo.svg" alt="alfa force logo" />
      </div>
      <div class="col bar-dx"></div>
    </div>
  </div>
</ng-template>
