import { Routes } from '@angular/router';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { GestioneTipologicheComponent } from '../../pages/tipologiche/gestione-tipologiche/gestione-tipologiche.component';
import { RoleGuard } from '../guard/role.guard';

export const content: Routes = [
  {
    path: 'profilo',
    children: [
      {
        path: 'visualizza',
        component: UserProfileComponent,
        data: {
          title: 'Profilo',
          breadcrumb: 'Profilo',
        },
      },
      {
        path: 'modifica',
        component: UserProfileComponent,
        data: {
          title: 'Profilo',
          breadcrumb: 'modifica Profilo',
        },
      },
    ],
  },
  {
    path: 'anagrafiche',
    loadChildren: () => import('../../pages/anagrafiche/Utenti/utenti.module').then((m) => m.UtentiModule),
    data: {
      title: 'Utenti',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'anagrafiche/strutture',
    loadChildren: () => import('../../pages/anagrafiche/strutture/strutture.module').then((m) => m.StruttureModule),
    data: {
      title: 'Strutture',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'anagrafiche/prodotti',
    loadChildren: () => import('../../pages/anagrafiche/prodotti/prodotti.module').then((m) => m.ProdottiModule),
    data: {
      title: 'Prodotti',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'anagrafiche/segmentazioni',
    loadChildren: () =>
      import('../../pages/anagrafiche/segmentazioni-geografiche/segmentazioni-geografiche.module').then((m) => m.SegmentazioniGeograficheModule),
    data: {
      title: 'Segmentazioni',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'tipologiche',
    component: GestioneTipologicheComponent,
    data: {
      title: 'Tipologiche',
      breadcrumb: 'Tipologiche',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'anagrafiche/attivita',
    loadChildren: () => import('../../pages/anagrafiche/Attivita/attivita.module').then((m) => m.AttivitaModule),
    data: {
      title: 'Attivita',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'anagrafiche/medici',
    loadChildren: () => import('../../pages/anagrafiche/medici/medici.module').then((m) => m.MediciModule),
    data: {
      title: 'Medici',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'saggi',
    loadChildren: () => import('../../pages/saggi/saggi.module').then((m) => m.SaggiModule),
    data: {
      title: 'Magazzino',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'approvazioni',
    loadChildren: () => import('../../pages/approvazioni/approvazioni.module').then((m) => m.ApprovazioniModule),
    data: {
      title: 'Approvazioni',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'cicli',
    loadChildren: () => import('../../pages/cicli/cicli.module').then((m) => m.CicliModule),
    data: {
      title: 'Cicli',
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'attivita',
    loadChildren: () => import('../../pages/attività/attivita.module').then((m) => m.AttivitaModule),
    data: {
      title: 'Attività',
    },
    canActivate: [RoleGuard],
  },
];
