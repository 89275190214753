import { Component, TemplateRef } from "@angular/core";
import { NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { TipologicheService } from "../tipologiche.service";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import Swal from "sweetalert2";
import { NgSelectModule } from "@ng-select/ng-select";
@Component({
  selector: "app-gestione-tipologiche",
  standalone: true,
  imports: [NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, FormsModule, NgForOf, NgIf, ReactiveFormsModule, NgClass, NgbTooltip, NgSelectModule],
  templateUrl: "./gestione-tipologiche.component.html",
  styleUrl: "./gestione-tipologiche.component.scss",
})
export class GestioneTipologicheComponent {
  dataTableTypology: any[] = [];
  dataTypeTypology: any[] = [];
  idTipologySelected: number = 5;
  modeModal: "nuovo" | "modifica" = "nuovo";
  typologyForm: FormGroup;
  validateTypologyForm = false;
  editable: boolean;
  protected idMacroCategory: number;
  protected macroCategories: any[] = [];
  protected moreValues: any[] = [];
  protected moreValuesToShow: number[] = [3, 4];
  constructor(public service: TipologicheService, private modalService: NgbModal, private fb: FormBuilder) {}

  get isProductLine(): boolean {
    const item = this.dataTypeTypology.find((item) => item.id === this.idTipologySelected);
    return item?.name === "Linea Prodotto";
  }

  get getMoreValues(): string {
    switch (this.idTipologySelected) {
      case 3:
        return "Specialità";
      case 4:
        return "Sottocategorie";
      default:
        return "";
    }
  }

  ngOnInit() {
    this.loadData();
    this.initForm();
  }

  initForm(): void {
    this.typologyForm = this.fb.group({
      idTypeTypology: [this.idTipologySelected],
      nameTypology: ["", Validators.required],
      children: [[]],
    });
  }

  loadData() {
    this.service.getSurgeryTypology().subscribe(
      (response: any) => {
        if (response.success) {
          this.dataTypeTypology = [];
          response.data.list.forEach((element: any) => {
            if (element.editable) {
              this.dataTypeTypology.push(element);
            }
          });
        }
      },
      (error) => {
        console.error("Error fetching surgery typology:", error);
      }
    );

    const sessionId = sessionStorage.getItem("idTipologica");
    this.moreValues = [];

    if (sessionId) {
      const idTipologica = +JSON.parse(sessionId);
      this.idTipologySelected = idTipologica;
    }

    this.service.getTypologyId(this.idTipologySelected).subscribe(
      (response: any) => {
        if (response.success) {
          if (this.isProductLine) {
            this.loadMacroCategories();
          }
          this.loadMoreValues();
          this.dataTableTypology = response.data;
          this.editable = response.data[0].typologyType.editable;
        }
      },
      (error) => {
        console.error("Error fetching typology ID:", error);
      }
    );
  }

  loadMacroCategories() {
    this.service.getMacroCategories().subscribe({
      next: (res: any) => {
        this.macroCategories = res.data;
      },
    });
  }

  loadMoreValues() {
    if (this.idTipologySelected === 3) {
      this.service.getTypologyId(1).subscribe({
        next: (res: any) => {
          this.moreValues = res.data;
        },
      });
    } else if (this.idTipologySelected === 4) {
      this.service.getTypologyId(15).subscribe({
        next: (res: any) => {
          this.moreValues = res.data;
        },
      });
    }
  }

  onTypeChange(event: Event) {
    let getTypologyId = (event.target as HTMLSelectElement).value;
    this.idTipologySelected = Number(getTypologyId);
    this.moreValues = [];
    sessionStorage.setItem("idTipologica", JSON.stringify(this.idTipologySelected));
    this.initForm();
    this.service.getTypologyId(getTypologyId).subscribe(
      (response: any) => {
        if (response.success) {
          if (this.isProductLine) {
            this.loadMacroCategories();
          }
          this.loadMoreValues();
          this.dataTableTypology = response.data;
          this.editable = response.data[0].typologyType.editable;
        }
      },
      (error) => {
        console.error("Errore durante il recupero dei dati:", error);
      }
    );
  }

  modalManageTipology(staticContent: TemplateRef<NgbModal>, item: any) {
    if (item.id === "") {
      this.modeModal = "nuovo";
    } else {
      this.modeModal = "modifica";
      this.typologyForm.get("nameTypology")?.patchValue(item.name);
      this.typologyForm.get("idTypeTypology")?.patchValue(item.id);
      if (this.isProductLine && item?.macroCategoryResponse) {
        this.idMacroCategory = item?.macroCategoryResponse.id;
      }
      if (item.children && item.children.length > 0) {
        this.typologyForm.get("children")?.patchValue(item.children.map((child: any) => child.id));
      }
    }
    const modalRef = this.modalService.open(staticContent, {
      centered: true,
    });
  }

  close() {
    this.modalService.dismissAll();
    this.typologyForm.reset();
    this.validateTypologyForm = false;
  }

  deleteTipology(idTypology: number, nameTypology: string) {
    Swal.fire({
      title: `Vuoi eliminare la tipologia?`,
      text: `La tipologia ${nameTypology} verrà rimossa dal sistema`,
      showCancelButton: true,
      confirmButtonText: "Elimina",
      cancelButtonText: "Annulla",
      reverseButtons: true,
      confirmButtonColor: "#0d9a71",
      cancelButtonColor: "red",
      icon: "warning",
      footer: `
        <div class="d-flex footer-sweetalert align-items-center overflow-hidden">
          <div class="col d-flex align-items-baseline justify-content-center">
            <img src="./../../../../assets/svg/alfa-force-logo.svg" alt="alfa force logo">
          </div>
          <div class="col bar-dx"></p>
        </div>
      `,
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deleteTipology(idTypology).subscribe({
          next: (res) => {
            this.loadData();
          },
          error: (err) => {},
        });
      }
    });
  }

  addOrUpdateTypology() {
    this.validateTypologyForm = !this.validateTypologyForm;
    const name: string | null = this.typologyForm.get("nameTypology")?.value || null;
    const id: number = this.idTipologySelected;
    let request: any = {
      name: name,
      id: this.modeModal === "nuovo" ? id : this.typologyForm.get("idTypeTypology")?.value,
      children: this.typologyForm.get("children")?.value,
    };

    if (this.isProductLine) {
      request["macroCategory"] = this.idMacroCategory;
    }
    if (this.typologyForm.valid) {
      const action = this.modeModal === "nuovo" ? this.service.storeTypology(request) : this.service.updateTypology(request);
      action.subscribe({
        next: (response) => {
          this.loadData();
          this.close();
        },
      });
    }
  }
}
