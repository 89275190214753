<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="wrap-select-tipology row justify-content-end mb-4 align-items-end">
            <div class="col-auto">
              <label class="form-label" for="type-tipology">Tipo Tipologica</label>
              <select class="form-select" id="type-tipology" required (change)="onTypeChange($event)">
                <option selected disabled value=""></option>
                <option *ngFor="let item of dataTypeTypology" [value]="item.id" [defaultSelected]="item.id === idTipologySelected">
                  {{ item.name }}
                </option>
              </select>
              <div class="invalid-feedback">Campo obbligatorio</div>
            </div>
            <div class="col-auto">
              <button
                class="btn small-square-primary button-icon"
                *ngIf="editable"
                ngbTooltip="Nuova tipologica"
                placement="top"
                tooltipClass="alfa-tooltip"
                container="body"
                (click)="modalManageTipology(staticContent, { id: '', name: '', macroCategoryResponse: null, children: [] })">
                <img src="./../../../../assets/svg/plus-icon.svg" alt="" />
              </button>
            </div>
          </div>
          <div class="table-responsive theme-scrollbar">
            <div class="dataTables_wrapper no-footer">
              <table class="display dataTable no-footer" id="table-users">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Nome</th>
                    <th *ngIf="isProductLine">Macro Categoria</th>
                    <th>Azione</th>
                  </tr>
                </thead>
                <tbody>
                  @for (item of dataTableTypology; track item) {
                  <tr>
                    <td>{{ item.typologyType?.name }}</td>
                    <td>{{ item?.name }}</td>
                    <td *ngIf="isProductLine">
                      {{ item?.macroCategoryResponse?.name || '---' }}
                    </td>
                    <td>
                      <ul class="action" *ngIf="item.typologyType?.editable === true">
                        <li>
                          <div ngbDropdown class="d-inline-block">
                            <button type="button" class="btn" [id]="'dropdownBasic-' + item.id" ngbDropdownToggle>
                              <img src="./../../../../assets/svg/3-dots.svg" alt="" />
                            </button>
                            <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownBasic-' + item.id">
                              <button ngbDropdownItem (click)="modalManageTipology(staticContent, item)">
                                <img src="./../../../../assets/svg/cta-edit.svg" alt="" class="small-cta-icon" />
                                Modifica
                              </button>
                              <button (click)="deleteTipology(item.id, item.name)" ngbDropdownItem>
                                <img src="./../../../../assets/svg/cta-trash.svg" alt="" class="small-cta-icon" />
                                Elimina
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #staticContent let-modal>
  <div class="modal-body text-start">
    <button
      type="button"
      ngbTooltip="Chiudi"
      placement="top"
      tooltipClass="alfa-tooltip"
      class="btn m-2 small-square-primary pull-right button-icon"
      (click)="close()">
      <img src="./../../../../assets/svg/close-icon.svg" alt="" />
    </button>
    <div class="modal-body-content p-5">
      <div class="modal-toggle-wrapper">
        <h4 class="text-dark">
          {{ modeModal === 'nuovo' ? 'Nuova Tipologia' : 'Modifica tipologia' }}
        </h4>
        <p>
          {{ modeModal === 'nuovo' ? 'Inserisci il nome della nuova ' : 'Modifica il nome della ' }}
          tipologia
        </p>
        <form
          #f="ngForm"
          [formGroup]="typologyForm"
          novalidate
          [ngClass]="{ 'was-validated': validateTypologyForm }"
          (ngSubmit)="addOrUpdateTypology()"
          class="theme-form">
          <div class="content">
            <div class="form-group mt-4" *ngIf="isProductLine">
              <label class="form-label" for="macroCateogry">Macrocategoria</label>
              <select class="form-select" id="macroCateogry" [(ngModel)]="idMacroCategory" [ngModelOptions]="{ standalone: true }" required>
                <option *ngFor="let item of macroCategories" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group mt-4">
              <label class="form-label" for="nameTypology">
                Nome
                <sup>*</sup>
              </label>
              <input class="form-control" formControlName="nameTypology" id="nameTypology" type="text" required="" />
              <div class="invalid-feedback">Campo obbligatorio</div>
            </div>
            <div class="form-group mt-4" *ngIf="moreValuesToShow.includes(idTipologySelected)">
              <label class="form-label">
                {{ getMoreValues }}
              </label>
              <ng-select
                [items]="moreValues"
                bindLabel="name"
                bindValue="id"
                class="selectResearch"
                placeholder="-"
                formControlName="children"
                [multiple]="true"
                [closeOnSelect]="false"></ng-select>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <button type="submit" class="btn btn-primary btn-fill">Salva</button>
            <div class="clearfix"></div>
          </div>
        </form>
      </div>
    </div>
    <div class="d-flex footer-sweetalert align-items-center overflow-hidden">
      <div class="col d-flex align-items-baseline justify-content-center">
        <img src="./../../../../assets/svg/alfa-force-logo.svg" alt="alfa force logo" />
      </div>
      <div class="col bar-dx"></div>
    </div>
  </div>
</ng-template>
