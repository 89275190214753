<div class="page-header row" [ngClass]="{ close_icon: navService.collapseSidebar }">
  <div class="header-logo-wrapper col-auto">
    <div class="logo-wrapper">
      <a href="javascript:void(0)">
        <img class="img-fluid for-light" src="assets/images/logo/logo_alfaforce_small.svg" alt="" />
        <img class="img-fluid for-dark" src="assets/images/logo/logo_alfaforce_small.svg" alt="" />
      </a>
    </div>
  </div>
  <div class="col-4 col-xl-4 page-title wrap-breadcumbs">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="header-wrapper col m-0">
    <div class="row">
      <form class="form-inline search-full col" [ngClass]="{ open: isSearchOpen }" (clickOutside)="searchService.clickOutside()">
        <div class="form-group w-100">
          <div class="Typeahead Typeahead--twitterUsers responsive-search">
            <div class="u-posRelative">
              <input
                class="demo-input Typeahead-input form-control-plaintext w-100"
                [(ngModel)]="searchService.text"
                [ngModelOptions]="{ standalone: true }"
                (keyup)="searchService.searchTerm(searchService.text)"
                type="text"
                placeholder="Search Mofi .." />
              <span (click)="isSearchOpen = !isSearchOpen">
                <app-feather-icons [class]="'close-search'" [icons]="'x'"></app-feather-icons>
              </span>
            </div>
            <div class="Typeahead-menu" [class.is-open]="searchService.searchResult" id="search-outer">
              <div class="ProfileCard u-cf" *ngFor="let menuItem of searchService.menuItems | slice : 0 : 8">
                <div class="ProfileCard-avatar"></div>
                <div class="ProfileCard-details">
                  <div class="ProfileCard-realName">
                    <app-svg-icon [icon]="menuItem?.icon"></app-svg-icon>
                    <a [routerLink]="menuItem?.path" class="realname" (click)="searchService.removeFix()">{{ menuItem?.title }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="Typeahead-menu" [class.is-open]="searchService.searchResultEmpty" (clickOutside)="searchService.clickOutside()">
              <div class="tt-dataset tt-dataset-0">
                <div class="EmptyMessage">Opps!! There are no result found.</div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="header-logo-wrapper col-auto p-0">
        <div class="logo-wrapper">
          <a href="javascript:void(0)">
            <img class="img-fluid" src="assets/images/logo/logo.png" alt="" />
          </a>
        </div>
        <div class="toggle-sidebar" (click)="sidebarToggle()">
            <img alt="" class="icon-sidebar" src="../../../../assets/svg/toggle-icon.svg">
          <!--<app-comman-svg-icon [class]="'stroke-icon sidebar-toggle status_toggle middle'" [icon]="'toggle-icon'"></app-comman-svg-icon>-->
        </div>
      </div>
      <div class="nav-right col-xxl-8 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
        <ul class="nav-menus">
         <li class="position-relative">
            <span class="header-search" (click)="isSearchOpen = !isSearchOpen">
              <app-comman-svg-icon [icon]="'search'"></app-comman-svg-icon>
            </span>
          </li>
          <li class="onhover-dropdown">
            <app-notitications></app-notitications>
          </li>
          <li class="onhover-dropdown">
            <app-messages></app-messages>
          </li>
          <li [routerLink]="['/profilo/visualizza']" class="profile-nav onhover-dropdown py-0">
            <app-profile></app-profile>
          </li>
          <!--<li>
              <a href="javascript:void(0)">
                <app-feather-icons [icons]="'settings'" [routerLink]="['/profilo/visualizza']"></app-feather-icons> <span></span>
              </a>
            </li>-->
          <li>
            <a href="javascript:void(0)" (click)="logOut()">
              <app-feather-icons [icons]="'log-out'"></app-feather-icons>
              <span></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
