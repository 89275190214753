import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CheckPassValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null; // Non validare se il campo è vuoto
        }

        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /\d/.test(value);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
        const isValidLength = value.length >= 6;

        const errors: any = {};

        if (!hasUpperCase) {
            errors["hasUpperCase"] = 'La password deve contenere almeno una lettera maiuscola';
        }

        if (!hasLowerCase) {
            errors['hasLowerCase'] = 'La password deve contenere almeno una lettera minuscola';
        }

        if (!hasNumber) {
            errors['hasNumber'] = 'La password deve contenere almeno un numero';
        }

        if (!hasSpecialChar) {
            errors['hasSpecialChar'] = 'La password deve contenere almeno un carattere speciale';
        }

        if (!isValidLength) {
            errors['isValidLength'] = 'La password deve essere lunga almeno 6 caratteri';
        }

        return Object.keys(errors).length ? errors : null;
    };
}
