import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  public customizer: string = "";

  public config = {
    settings: {
      layout_type: "ltr",
      layout_version: "dark-sidebar",
      sidebar_type: "compact-wrapper",
      icon: "stroke-svg",
    },
    color: {
      primary_color: "#7A70BA",
      secondary_color: "#48A3D7",
    },
  };

  private activeCalls = 0;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  constructor() {}

  show() {
    this.activeCalls++;
    this.updateLoadingState();
  }

  hide() {
    if (this.activeCalls > 0) {
      this.activeCalls--;
    }
    this.updateLoadingState();
  }

  private updateLoadingState() {
    this.loadingSubject.next(this.activeCalls > 0);
  }
}
