import {Component, TemplateRef} from '@angular/core';
import {BasicDataTableDirective} from "../../../shared/directives/basic-data-table.directive";
import {
    NgbDropdown,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle, NgbModal, NgbTooltip
} from "@ng-bootstrap/ng-bootstrap";
import { TipologicheService } from "../tipologiche.service";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import Swal from "sweetalert2";

@Component({
  selector: 'app-gestione-tipologiche',
  standalone: true,
    imports: [
        BasicDataTableDirective,
        NgbDropdown,
        NgbDropdownButtonItem,
        NgbDropdownItem,
        NgbDropdownMenu,
        NgbDropdownToggle,
        FormsModule,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgbTooltip
    ],
  templateUrl: './gestione-tipologiche.component.html',
  styleUrl: './gestione-tipologiche.component.scss'
})
export class GestioneTipologicheComponent {

  dataTableTypology: any[] = [];
  dataTypeTypology: any[] = [];
  idTipologySetected: number = 5; //il primo selezionato è intervento id=5
  modeModal: 'nuovo' | 'modifica' = 'nuovo';
  typologyForm: FormGroup;
  validateTypologyForm = false;
  editable:boolean;

  constructor(
      public service: TipologicheService,
      private modalService: NgbModal,
      private fb: FormBuilder,
      ) {
  }

  ngOnInit(){
    this.loadData();
    this.initForm();
  }

  loadData(){
    this.service.getSurgeryTypology().subscribe(
        (response: any) => {
          if (response.success) {
              response.data.list.forEach((element : any) => {
                if (element.editable) {
                  this.dataTypeTypology.push(element)
                }
              });
          }
        },
        (error) => {
          console.error('Errore durante il recupero dei dati:', error);
          // Gestione degli errori
        }
    );
      const sessionId = sessionStorage.getItem("idTipologica")
     
      if( sessionId ){
        const idTipologica = +JSON.parse(sessionId)
        this.idTipologySetected = idTipologica; 
      }



    this.service.getTypologyId(this.idTipologySetected).subscribe(
        (response: any) => {
          if (response.success) {
            this.dataTableTypology= response.data;
            this.editable = response.data[0].typologyType.editable
          }
        },
        (error) => {
          console.error('Errore durante il recupero dei dati:', error);
        }
    );
  }

  onTypeChange(event: Event) {
    let getTypologyId = (event.target as HTMLSelectElement).value;
    this.idTipologySetected = Number(getTypologyId);
    sessionStorage.setItem("idTipologica", JSON.stringify(this.idTipologySetected))
    this.initForm();
    this.service.getTypologyId(getTypologyId).subscribe(
        (response: any) => {
          if (response.success) {
            
            this.dataTableTypology = response.data;
            this.editable = response.data[0].typologyType.editable;

          }
        },
        (error) => {
          console.error('Errore durante il recupero dei dati:', error);
          // Gestione degli errori
        }
    );
  }

    deleteTipology(idTypology:number, nameTypology:string){
      Swal.fire({
        title: `Vuoi eliminare la tipologia?`,
        text: `La tipologia ${nameTypology} verrà rimossa dal sistema`,
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Annulla",
        reverseButtons: true,
        confirmButtonColor: '#0d9a71',
        cancelButtonColor: 'red',
        icon: "warning",
        footer: `
        <div class="d-flex footer-sweetalert align-items-center overflow-hidden">
          <div class="col d-flex align-items-baseline justify-content-center">
            <img src="./../../../../assets/svg/alfa-force-logo.svg" alt="alfa force logo">
          </div>
          <div class="col bar-dx"></p>
        </div>
      `
      }).then((result) => {
        if (result.isConfirmed) {
          this.service.deleteTipology(idTypology).subscribe({
            next: (res) => {
      
              this.loadData()
            },
            error: (err) => {
            }
          })
        }
      });
    }

  modalManageTipology(staticContent: TemplateRef<NgbModal>, idTypology:any, nameTypology:string){
    console.log(this.idTipologySetected);
    console.log('editable:'+ this.editable);

    if(idTypology === ""){
      this.modeModal = 'nuovo'
    }else{
      this.modeModal = 'modifica';
      this.typologyForm.get('nameTypology')?.patchValue(nameTypology);
      this.typologyForm.get('idTypeTypology')?.patchValue(idTypology);
    }
    const modalRef = this.modalService.open(staticContent);
  }
  close(){
    this.modalService.dismissAll();
    this.typologyForm.reset();
    this.validateTypologyForm = false;
  }

  initForm(): void {
    this.typologyForm = this.fb.group({
      idTypeTypology: [this.idTipologySetected],
      nameTypology: ['', Validators.required],
    });

  }

  buildRequest(name:string, id:number){
    return{
      name:name,
      id:id
    }
  }

  addOrUpdateTypology(){
    this.validateTypologyForm = !this.validateTypologyForm;
    const name = this.typologyForm.get('nameTypology')?.value || null;
    const id = this.typologyForm.get('idTypeTypology')?.value || null;
    const request= this.buildRequest(name, id);
    console.log(request);
    if (this.typologyForm.valid) {
      if(this.modeModal === 'nuovo'){
        this.service.storeTypology(request).subscribe({
          next: (response) => {
            //this.dataTableTypology.push(request);

            this.loadData()
            this.close();
          },
          error: (error) => {
            console.error('There was an error!', error);
          }
        });
      } else {
        this.service.updateTypology(request).subscribe({
          next: (response) => {
            this.loadData()
            this.close();
          },
          error: (error) => {
            console.error('There was an error!', error);
          }
        });
      }
    }

  }

}
