import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { Location, NgClass, NgForOf, NgIf } from "@angular/common";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PasswordValidation } from "./password-validator.component";
import { CheckPassValidator } from "./checkPass-validator";
import { AuthService } from "../auth/auth.service";
import { NgSelectModule } from "@ng-select/ng-select";

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    RouterLink,
    NgClass,
    NgForOf,
    NgSelectModule
  ],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit, OnDestroy {

  mode: 'visualizza' | 'modifica' = 'visualizza';
  profiloForm: FormGroup;
  changePasswordForm: UntypedFormGroup;
  public validate = false;
  public validatePasswordForm = false;
  subscriptions: Subscription[] = [];
  user: any;
  $changePswCall: Subscription;
  productLinee: any = [];
  show: boolean = false;
  show2: boolean = false;
  show3: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private fbUn: UntypedFormBuilder,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.setupFormChangePsw();
    this.route.params.subscribe(params => {
      this.mode = this.route.snapshot.routeConfig?.path?.startsWith('modifica') ? 'modifica' : 'visualizza';
      this.getDataUser();
    });
    if (this.mode === 'visualizza') {
      this.profiloForm.disable();
    } else {
      this.profiloForm.enable();
    }
  }

  showPassword() {
    this.show = !this.show;
    this.show2 = !this.show2;
  }

  getDataUser() { // show user data
    const sub = this.authService.getInfoAccount().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.user = res.data;
          this.patchUserData(res.data);
        }
      }
    });
    this.subscriptions.push(sub);
  }

  initForm(): void {
    this.profiloForm = this.fb.group({
      email: [''],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      numberPhone: ['', Validators.required],
      role: ['', Validators.required],
      productLinee: [[], Validators.required],
    });
  }

  patchUserData(data: any): void {
    this.profiloForm.patchValue({
      name: data.name,
      surname: data.surname,
      email: data.email,
      role: data.role.name,
    });
    this.productLinee = data.productLinee
    this.profiloForm.get('productLinee')?.patchValue(this.populateProductLinee(data.productLinee));
  }

  populateProductLinee(productLinee: any[]): any[] {
    let preselectedIds: any[] = [];
    productLinee.forEach((linea) => {
      preselectedIds.push(linea.id);
      //this.utenteForm.get('productLinee')?.patchValue(linea.id) || null;
    });
    return preselectedIds;
  }

  buildRequestTemplate(name: string, surname: string, numberPhone: string, role: string) {
    return {
      name: name,
      surname: surname,
      numberPhone: numberPhone,
      role: role,
    };
  }
  onSubmit() {
    this.validate = !this.validate;
    const name = this.profiloForm.get("name")?.value || null;
    const surname = this.profiloForm.get("surname")?.value || null;
    const numberPhone = this.profiloForm.get("numberPhone")?.value || null;
    const role = this.profiloForm.get("role")?.value || null;
    const request = this.buildRequestTemplate(
      name,
      surname,
      numberPhone,
      role
    );
    const sub = this.authService.updateUserAdmin(request).subscribe({ // call API to edit account
      next: (res: any) => {
        if (res.success) {
          this.toastr.success(res.message, 'Operazione eseguita con successo!', {
            positionClass: 'toast-bottom-right',
            tapToDismiss: true,
          });
        } else {
          this.toastr.error(res.message, 'Attenzione.', {
            positionClass: 'toast-bottom-right',
            tapToDismiss: true,
          });
        }
      },
      error: err => {
        let message = err.error.message + ": ";
        if (err.error.data) {
          for (const error in err.error.data.errors) {
            if (typeof (err.error.data.errors[error]) !== "undefined") {
              message += err.error.data.errors[error][0] + " ";
            }
          }
        }
        this.toastr.error(message, 'Attenzione!', {
          positionClass: 'toast-bottom-right',
          tapToDismiss: true,
        });
      }
    });
    this.subscriptions.push(sub);
  }

  goBack(): void {
    this.location.back();
  }

  openStaticModal(staticContent: TemplateRef<NgbModal>) {
    const modalRef = this.modalService.open(staticContent);
  }

  close() {
    this.modalService.dismissAll();
  }

  setupFormChangePsw() {
    this.changePasswordForm = this.fbUn.group({
      oldPassword: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required, CheckPassValidator()]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    },
      { validators: PasswordValidation('password', 'confirmPassword') }
    );
  }

  buildRequestChangePswd(oldPassword: string, newPassword: string, confirmPassword: string) {
    return {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    };
  }

  saveNewPassword() {
    //this.validatePasswordForm = !this.validatePasswordForm;
    const oldPassword = this.changePasswordForm.get("oldPassword")?.value || null;
    const password = this.changePasswordForm.get("password")?.value || null;
    const confirmPassword = this.changePasswordForm.get("confirmPassword")?.value || null;
    const request = this.buildRequestChangePswd(
      oldPassword,
      password,
      confirmPassword
    );
    if (this.changePasswordForm.valid) {
      this.$changePswCall = this.authService.changePassword(request).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.close();
          }
        },
        error: err => {
        }
      });
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    if (this.$changePswCall) this.$changePswCall.unsubscribe();
  }

}
