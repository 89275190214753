import { Component, OnInit } from "@angular/core";
import { CommonServiceService } from "../../../services/common-service.service";

@Component({
  selector: "app-notitications",
  templateUrl: "./notitications.component.html",
  styleUrls: ["./notitications.component.scss"],
})
export class NotiticationsComponent implements OnInit {
  public isShow: boolean = false;
  notificationList: any = [];

  constructor(private service: CommonServiceService) {}

  ngOnInit(): void {
    this.loadNotificationList();
  }

  loadNotificationList() {
    this.service.getAllNotifications().subscribe({
      next: (res) => {
        this.notificationList = res.data.list;
      },
      error: () => {},
    });
  }

  markAsRead(notificationId: number): void {
    this.service.markAsRead(notificationId).subscribe(() => {
      this.notificationList = this.notificationList.filter((notification: any) => {
        return notification.id !== notificationId;
      });
    });
  }
}
