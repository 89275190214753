<div class="cart-box" (click)="isShow =!isShow">
<app-comman-svg-icon [icon]="'stroke-ecommerce'"></app-comman-svg-icon>
</div>
<div class="cart-dropdown onhover-show-div" [class.active]="isShow">
  <h5 class="f-18 f-w-600 mb-0 dropdown-title">Cart</h5>
  <ul>
    <li>
      <div class="d-flex">
        <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/other-images/cart-img.jpg" alt="">
        <div class="flex-grow-1">
          <span class="f-w-600">Furniture Chair for Home</span>
          <div class="qty-box">
            <div class="input-group">
              <span class="input-group-prepend">
                <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button>
              </span>
              <input class="form-control input-number" type="text" name="quantity" value="1">
              <span class="input-group-prepend">
                <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button>
              </span>
            </div>
          </div>
          <h6 class="font-primary">$500</h6>
        </div>
        <div class="close-circle">
          <a class="bg-danger" href="javascript:void(0)">
            <app-feather-icons [icons]="'x'"></app-feather-icons>
          </a>
        </div>
      </div>
    </li>
    <li>
      <div class="d-flex">
        <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/other-images/table-img.jpg" alt="">
        <div class="flex-grow-1">
          <span class="f-w-600">Furniture Table for Office</span>
          <div class="qty-box">
            <div class="input-group">
              <span class="input-group-prepend">
                <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button>
              </span>
              <input class="form-control input-number" type="text" name="quantity" value="1">
              <span class="input-group-prepend">
                <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button>
              </span>
            </div>
          </div>
          <h6 class="font-primary">$500.00</h6>
        </div>
        <div class="close-circle">
          <a class="bg-danger" href="javascript:void(0)">
            <app-feather-icons [icons]="'x'"></app-feather-icons>
          </a>
        </div>
      </div>
    </li>
    <li class="total">
      <h6 class="mb-0">Order Total : <span class="f-w-600 f-right">$1000.00</span></h6>
    </li>
    <li class="text-center"><a class="d-block mb-3 view-cart f-w-700" href="javascript:void(0)">Go to your cart</a>
      <a class="btn btn-primary view-checkout" href="javascript:void(0)">Checkout</a>
    </li>
  </ul>
</div>