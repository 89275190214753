import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})

export class ProfileComponent implements OnInit{

  public isShow: boolean = false;

  currentUser: any;
  name: string;
  surname: string;
  role: any;


  constructor(public router: Router) {
  }

  ngOnInit() {
    const user = localStorage.getItem('currentUser');
    if (user) {
      this.currentUser = JSON.parse(user);
      this.name = this.currentUser.name;
      this.surname = this.currentUser.surname;
      this.role = this.currentUser.role.name;
    }
  }

  logOut() {
    localStorage.clear();
    this.router.navigateByUrl("/auth/login");
  }
  
}
