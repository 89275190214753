<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h4>Sample Card</h4><span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                </div>
                <div class="card-body">
                    <p>"Sample-page" is a generic term used to refer to a basic, placeholder, or example page that
                        developers or designers use as a starting point for building or testing a website or
                        application. It is not an official or standard term but rather a descriptive name commonly used
                        in web development and design contexts A sample page typically contains basic elements like
                        headings, paragraphs, images, buttons, and links. It may also include placeholder text or images
                        to represent content that will be replaced with actual content in the final version.</p>
                </div>
            </div>
        </div>
    </div>
</div>