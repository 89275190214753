<div class="form-group w-100" (clickOutside)="searchService.clickOutside()">
  <div class="Typeahead Typeahead--twitterUsers">
    <div class="u-posRelative d-flex align-items-center">
      <app-comman-svg-icon [icon]="'search'"></app-comman-svg-icon>
      <input class="demo-input py-0 Typeahead-input form-control-plaintext w-100" type="text"
        placeholder="Search Mofi .." [(ngModel)]="searchService.text" [ngModelOptions]="{ standalone: true }"
        (keyup)="searchService.searchTerm(searchService.text)">
    </div>
    <div class="Typeahead-menu custom-scrollbar" [class.is-open]="searchService.searchResult"
      *ngIf="searchService.menuItems?.length" id="search-outer">
      <div class="ProfileCard u-cf" [routerLink]="menuItem?.path"
        *ngFor="let menuItem of searchService.menuItems | slice : 0 : 8" (click)="searchService.removeFix()">
        <div class="ProfileCard-details">
          <div class="ProfileCard-realName">
            <app-svg-icon [icon]="menuItem?.icon"></app-svg-icon>
            <a class="realname">{{ menuItem?.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="Typeahead-menu is-open" [class.is-open]="searchService.searchResultEmpty" (clickOutside)="searchService.clickOutside()">
  <div class="tt-dataset tt-dataset-0">
    <div class="EmptyMessage">Opps!! There are no result found.</div>
  </div>
</div>