import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { Router } from "@angular/router";
import {Credentials} from "../../shared/models/credentials";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //private headersApp_Json: HttpHeaders;
  private HTTPheaders;
  private options;

  // simulare il login
  private isAuthenticated: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.HTTPheaders = ({
      'Content-Type': 'application/json',
    });
    this.options = ({
      headers: this.HTTPheaders,
    })
    //this.headersApp_Json = new HttpHeaders().set('Content-Type', 'application/json');
  }

  login(credentials: Credentials): Observable<object> {
    return this.http.post(env.baseUrl + 'Auth', credentials, this.options);
  }

  async checkToken() {
    if (localStorage.getItem('token')) {
      this.isAuthenticated = true;
      return true;
    } else {
      this.isAuthenticated = false;
      return false;
    }
  }

  logout(): void {
    this.isAuthenticated = false;
    localStorage.clear();
    this.router.navigate(['/auth']);
  }

  isLoggedIn(): boolean {
    this.checkToken()
    return this.isAuthenticated;
  }

  changePassword(request: any): Observable<object> {
    return this.http.put(env.baseUrl + 'Auth/ChangePassword', request, this.options);
  }

  getInfoAccount(): Observable<Object> {
    return this.http.get(env.baseUrl + 'Auth', this.options); // GET DATA USER ADMIN
  }

  updateUserAdmin(request: any): Observable<Object> {
    return this.http.post(env.baseUrl + "update-profile", request, this.options); // UPDATE USER ADMIN
  }

  resetPassword(request: any): Observable<object> {
    return this.http.post(env.baseUrl + 'Auth/ResetPassword', request, this.options);
  }

  refreshToken(token: string): Observable<object> {
    return this.http.get(env.baseUrl + `Auth/RefreshToken?JwtToken=${token}`, this.options);
  }

  getUser() {
    return JSON.parse(localStorage.getItem('currentUser')!);
  }

  getRole() {
    const user = this.getUser();
    return user ? user.role.id : null;
  }


}
