import {Component, OnInit} from '@angular/core';
import { CommonServiceService } from "../../../services/common-service.service";

@Component({
  selector: 'app-notitications',
  templateUrl: './notitications.component.html',
  styleUrls: ['./notitications.component.scss']
})

export class NotiticationsComponent implements OnInit{

  public isShow : boolean = false;
  notificationList: any = [];

  constructor(
      private notificationService: CommonServiceService
  ) {
  }

  ngOnInit():void{
    this.loadNotifivationList();
  }

  loadNotifivationList(){
    this.notificationService.getAllNotifications().subscribe({ // select Area
      next: (res) => {
        this.notificationList = res.data.list;
      },
      error: () => { }
    });
  }

  markAsRead(notificationId: number): void {
    this.notificationService.markAsRead(notificationId).subscribe(
        () => {
          this.notificationList = this.notificationList.filter((notification: any) => {  // Rimuove la notifica dalla lista dopo che è stata segnata come letta
            return notification.id !== notificationId;
          });
        },
        (error) => {
          console.error('Errore nel segnare la notifica come letta', error);
        }
    );
  }

}
