import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment as env, environment} from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  private headersApp_Json: HttpHeaders;

  constructor(
      private http: HttpClient
  ) {
   this.headersApp_Json = new HttpHeaders().set('Content-Type', 'application/json');
  }

  /*getArea(): Observable<any> {
    return this.http.get<any>("https://mocki.io/v1/10ce0351-fc66-4601-b364-6b30c59ba045"); // GET ALL AREA Area
  }*/
  getArea(page?: number, size?: number, filters?: any): Observable<any> {
    const params = new HttpParams({ fromObject: filters })
    return this.http.get<any>(env.baseUrl + "Area" + '?page=' + page + '&pageResults=' + size + '&' + params); // GET ALL AREA
  }
  getAreaId(id: string): Observable<any> {
    return this.http.get<any>(env.baseUrl + `Area/${id}`);// GET AREA ID ==> ZONE
  }
  getZone(): Observable<any> {
    return this.http.get<any>("https://mocki.io/v1/6093be29-6dec-4c8d-ab51-f73026ee2fce"); // GET ALL ZONA
  }
  getZoneId(id: string): Observable<any> {
    // return this.http.get<any>(`https://mocki.io/v1/64ec30be-0030-4b1a-9b69-7548ef0e94ab`);// GET ZONE ID - Frazioni
    return this.http.get<any>(env.baseUrl + `Zone/${id}`);// GET ZONE ID - Frazioni
  }
  getprovincialFractions(): Observable<any> {
    return this.http.get<any>("https://mocki.io/v1/e2c37de8-b7bd-47ca-ae16-fa45712fe552"); // GET FRAZIONE PROVINCIA
  }
  getprovincialFractionsId(id: string): Observable<any> {
    return this.http.get<any>(`https://mocki.io/v1/bb43fd49-7fd4-4e0d-931a-9e0cf1d40227`);
  }

  getProductLinee(): Observable<any> {
    return this.http.get<any>("https://mocki.io/v1/d4ef40cc-b316-4a3d-b3e1-57c32b59f718"); // LINEE PRODOTTO
  } // TODO: sostituire con la getById Typology perchè la linea prodotto apparterrà alle tipologiche

  getAllNotifications(): Observable<any> {
    return this.http.get<any>(env.baseUrl + `Notification`); // LISTA NOTIFICHE
  }

  markAsRead(notificationId: number): Observable<any> {
    return this.http.put<any>(env.baseUrl + `Notification/${notificationId}`, { read: true }); // Metodo per segnare la notifica come letta
  }

  addAddtionalFields(field: any): Observable<any> {
    return this.http.post<any>(env.baseUrl + `Field`, field); // post additional fields modal
  }

  getAddtionalFields(type:any): Observable<any> {
    return this.http.get<any>(env.baseUrl + `Field?type=${type}`); // post additional fields modal
  }


}
