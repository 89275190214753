<div class="notification-box" (click)="isShow =!isShow">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="41.806" height="38.502" viewBox="0 0 41.806 38.502"><defs><clipPath id="a"><rect width="41.806" height="38.502"/></clipPath></defs><g transform="translate(0 0)" clip-path="url(#a)"><path d="M20.922,0q7.885,0,15.771,0a4.907,4.907,0,0,1,4.961,3.817,6.225,6.225,0,0,1,.146,1.41q.013,10.16,0,20.32a4.986,4.986,0,0,1-5.262,5.255Q22.769,30.812,9,30.8a1.34,1.34,0,0,0-1.149.542C6.039,33.49,4.2,35.62,2.378,37.757c-.118.139-.239.276-.368.405a1.106,1.106,0,0,1-1.162.282,1.1,1.1,0,0,1-.818-.935A3.047,3.047,0,0,1,0,37.019Q0,21.08,0,5.142A4.957,4.957,0,0,1,4.116.079,6.91,6.91,0,0,1,5.37,0Q13.146,0,20.922,0M2.38,34.125c1.422-1.664,2.762-3.181,4.039-4.748a2.348,2.348,0,0,1,2.013-.943q14.048.038,28.1.015a2.586,2.586,0,0,0,2.9-2.907q0-10.135,0-20.269A2.613,2.613,0,0,0,36.5,2.337c-10.388,0-20.776.015-31.164-.016A2.787,2.787,0,0,0,2.359,5.31c.045,9.367.021,18.735.021,28.1Z" transform="translate(0 0.001)"/><path d="M58.5,76.205H71.366c1.053,0,1.586.388,1.595,1.153s-.549,1.192-1.615,1.192H45.67a4.379,4.379,0,0,1-.6-.015,1.181,1.181,0,0,1-1.024-1.17,1.15,1.15,0,0,1,1.044-1.139,5.7,5.7,0,0,1,.656-.021H58.5" transform="translate(-37.6 -65.054)"/><path d="M51.152,127.779h5.526a4.712,4.712,0,0,1,.546.018,1.165,1.165,0,0,1-.014,2.324c-.508.038-1.021.016-1.531.016q-5.034,0-10.068,0a2.721,2.721,0,0,1-.754-.08,1.132,1.132,0,0,1-.816-1.16,1.151,1.151,0,0,1,.985-1.081,3.845,3.845,0,0,1,.6-.032q2.763,0,5.526,0" transform="translate(-37.595 -109.081)"/></g></svg>
  <!--<app-comman-svg-icon [icon]="'header-message'"></app-comman-svg-icon>-->
  <span class="badge rounded-pill badge-info">0 </span>
</div>
<div class="onhover-show-div notification-dropdown" [class.active]="isShow">
  <!--<h5 class="f-18 f-w-600 mb-0 dropdown-title">Messages</h5>
  <ul class="messages">
    <li class="d-flex b-light1-primary gap-2">
      <div class="flex-shrink-0">
        <img src="assets/images/dashboard-2/user/1.png" alt="Graph">
      </div>
      <div class="flex-grow-1">
        <a href="javascript:void(0)">
          <h6 class="font-primary f-w-600">Hackett Yessenia</h6>
        </a>
        <p>Hello Miss... 🥳 </p>
      </div><span>10 min.</span>
    </li>
    <li class="d-flex b-light1-secondary gap-2">
      <div class="flex-shrink-0">
        <img src="assets/images/dashboard-2/user/2.png" alt="Graph">
      </div>
      <div class="flex-grow-1">
        <a href="javascript:void(0)">
          <h6 class="font-secondary f-w-600">schneider Adan</h6>
        </a>
        <p>Wishing You a Happy Birthday Dear.. 🥳</p>
      </div><span>25 min.</span>
    </li>
    <li class="d-flex b-light1-success gap-2">
      <div class="flex-shrink-0">
        <img src="assets/images/dashboard-2/user/3.png" alt="Graph">
      </div>
      <div class="flex-grow-1">
        <a href="javascript:void(0)">
          <h6 class="font-success f-w-600">Mahdi Gholizadeh</h6>
        </a>
        <p>Hello Dear!! This Theme Is Very beautiful</p>
      </div>
      <span>1 hours</span>
    </li>
    <li>
      <a class="f-w-700" href="javascript:void(0)">Check all</a>
    </li>
  </ul>-->
</div>
