<div *ngIf="!isMultiRowGroup">
  <!-- input Base  -->
  <div *ngIf="inputData?.inputType === 'input' && inputData?.labelName !== 'productDoctor'" class="form-group">
    <label class="form-label" [for]="inputData.labelName">
      {{ inputData.labelName }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <input
      (input)="onInputChange($event)"
      class="form-control"
      [id]="inputData?.id"
      [value]="inputData?.value ? inputData?.value : ''"
      [type]="inputData?.dataType == 'Int' ? 'number' : 'text'"
      [readOnly]="mode === 'visualizza' || inputData?.readOnly" />
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>

  <!-- input TextArea  -->
  <div *ngIf="inputData?.inputType === 'textarea'" class="form-group">
    <label class="form-label" [for]="inputData.labelName">
      {{ inputData.labelName }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <textarea
      (input)="onInputChange($event)"
      class="form-control"
      rows="3"
      [value]="inputData?.value ? inputData?.value : ''"
      [readOnly]="mode === 'visualizza' || inputData?.readOnly"></textarea>
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>

  <!-- Input MultiRow -->
  <div *ngIf="inputData?.inputType === 'select' && inputData?.multiRow && multiRowData.length > 0">
    <div
      *ngFor="let item of multiRowData; index as i"
      class="form-group d-flex align-items-center justify-content-between gap-2"
      [ngClass]="{ 'mb-3': i !== multiRowData.length - 1 }">
      <div [style]="{ width: item?.quantityField ? '60%' : '90%' }">
        <label class="form-label" [for]="item?.labelName">
          {{ item?.labelName }}
          <sup *ngIf="item?.mandatory && mode !== 'visualizza'">*</sup>
        </label>
        <ng-select
          [items]="data ? data : inputData?.data"
          [searchable]="true"
          [ngModel]="item?.value"
          [multiple]="item?.multiSelect"
          [closeOnSelect]="item?.multiSelect ? false : true"
          [bindLabel]="bindedLabel"
          bindValue="id"
          class="selectResearch"
          [ngClass]="{ 'single-control': !item?.multiSelect }"
          (ngModelChange)="onInputChange($event, 'select', i)"
          [disabled]="mode === 'visualizza'"></ng-select>
        <div class="text-danger" *ngIf="item?.mandatory && isInvalid">
          <div>Campo obbligatorio</div>
        </div>
      </div>
      <div style="width: 30%" *ngIf="item?.quantityField">
        <label class="form-label" [for]="'quantità'">Quantità</label>
        <div class="d-flex align-items-center justify-content-between gap-1">
          <button
            *ngIf="mode !== 'visualizza'"
            class="btn small-square-primary button-add-object"
            type="button"
            (click)="item.quantity = item.quantity > 0 ? item.quantity - 1 : 0">
            <img style="height: auto; width: 20px; filter: invert()" src="../../../../assets/svg/toggle-row-minus.svg" alt="Meno" />
          </button>
          <input
            (input)="onInputChange($event, 'quantity', i)"
            class="form-control"
            [id]="item?.id"
            [value]="item?.quantity ? item?.quantity : 0"
            [type]="item?.dataType == 'Int' ? 'number' : 'text'"
            [disabled]="mode === 'visualizza'" />
          <button
            *ngIf="mode !== 'visualizza'"
            class="btn small-square-primary button-add-object"
            type="button"
            (click)="item.quantity = item.quantity + 1">
            <img style="height: auto; width: 20px; filter: invert()" src="../../../../assets/svg/toggle-row-plus.svg" alt="Aggiungi" />
          </button>
        </div>
      </div>
      <div style="width: 10%" class="d-flex gap-1">
        <button *ngIf="i > 0 && mode !== 'visualizza'" class="btn btn-small-plus small-delete-icon" type="button" (click)="removeRow(i)">
          <img src="../../../../assets/svg/trash-icon.svg" alt="Elimina" />
        </button>
        <button
          *ngIf="multiRowData.length === i + 1 && mode !== 'visualizza'"
          class="btn btn-small-plus small-delete-icon"
          type="button"
          (click)="addRow()">
          <img src="../../../../assets/svg/small-plus-icon.svg" alt="Aggiungi" />
        </button>
      </div>
    </div>
  </div>

  <!-- input Select  -->
  <div *ngIf="inputData?.inputType === 'select' && !inputData?.multiRow" class="form-group">
    <label class="form-label" [for]="inputData.labelName">
      {{ inputData.labelName }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <img
      *ngIf="inputData?.moreInfo"
      (click)="clickDoctorInfo(inputData?.value)"
      class="info"
      height="16px"
      width="16px"
      src="/../../../../assets/svg/doctorInfo.png"
      alt="info" />
    <ng-select
      [items]="data ? data : inputData?.data"
      [ngModel]="inputData?.value"
      [multiple]="inputData?.multiSelect"
      [closeOnSelect]="inputData?.multiSelect ? false : true"
      [bindLabel]="bindedLabel"
      bindValue="id"
      class="selectResearch"
      [ngClass]="{ 'single-control': !inputData?.multiSelect }"
      (ngModelChange)="onInputChange($event, 'select')"
      [disabled]="mode === 'visualizza' || isInputDisabled || inputData?.readOnly"></ng-select>
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>

  <!-- input Date  -->
  <div *ngIf="inputData?.inputType === 'date'" class="form-group">
    <label class="form-label" [for]="inputData.labelName">
      {{ inputData.labelName }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <input
      class="datepicker-here form-control digits"
      id="startDate"
      placeholder="gg-mm-aaaa"
      [value]="formattedDate"
      [markDisabled]="isDisabled"
      name="dp"
      ngbDatepicker
      #d="ngbDatepicker"
      (click)="d.toggle()"
      (dateSelect)="onDateChange($event)"
      (input)="onInputChange($event, 'date')"
      [disabled]="mode === 'visualizza'" />
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>

  <!-- input CheckBox  -->
  <div *ngIf="inputData?.inputType === 'checkbox'" class="form-group">
    <label class="form-label" [for]="inputData.labelName">
      {{ inputData.labelName }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <div class="checkbox m-squar">
      <input
        [id]="inputData?.id"
        type="checkbox"
        [checked]="inputData?.value ? true : false"
        [value]="inputData?.value ? true : false"
        (change)="onInputChange($event, 'checkbox')"
        [disabled]="mode === 'visualizza'" />
      <span class="checkmark"></span>
    </div>
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>
</div>

<!-- MultiRowGroup -->

<div *ngIf="isMultiRowGroup" class="row mt-3">
  <div *ngFor="let singleGroup of multiGroupData; let index = index" class="row d-flex align-items-center mt-3">
    <div
      *ngFor="let multiGroupField of singleGroup; let i = index"
      [ngClass]="{
        'col-5': multiGroupField?.inputType === 'select',
        'col-2': multiGroupField?.inputType !== 'select' && multiGroupField?.inputType !== 'checkbox',
        'col-1': multiGroupField?.inputType === 'checkbox'
      }"
      class="d-flex align-items-center mt-2">
      <!-- input Base  -->
      <div *ngIf="multiGroupField?.inputType === 'input'" class="form-group">
        <label class="form-label" [for]="multiGroupField.labelName">
          {{ multiGroupField.labelName }}
          <sup *ngIf="multiGroupField?.mandatory && mode !== 'visualizza'">*</sup>
        </label>
        <input
          (input)="onInputMultiGroupChange($event, 'input', index, i)"
          class="form-control"
          [id]="multiGroupField?.id"
          [value]="multiGroupField?.value ? multiGroupField?.value : ''"
          [type]="multiGroupField?.dataType == 'Int' ? 'number' : 'text'"
          [disabled]="mode === 'visualizza' || multiGroupField?.readOnly" />
        <div class="text-danger" *ngIf="multiGroupField?.mandatory && isInvalid">
          <div>Campo obbligatorio</div>
        </div>
      </div>

      <!-- input TextArea  -->
      <div *ngIf="multiGroupField?.inputType === 'textarea'" class="form-group">
        <label class="form-label" [for]="multiGroupField.labelName">
          {{ multiGroupField.labelName }}
          <sup *ngIf="multiGroupField?.mandatory && mode !== 'visualizza'">*</sup>
        </label>
        <textarea
          (input)="onInputMultiGroupChange($event, 'textarea', index, i)"
          class="form-control"
          rows="3"
          [value]="multiGroupField?.value ? multiGroupField?.value : ''"
          [disabled]="mode === 'visualizza'"></textarea>
        <div class="text-danger" *ngIf="multiGroupField?.mandatory && isInvalid">
          <div>Campo obbligatorio</div>
        </div>
      </div>

      <!-- input Select  -->
      <div *ngIf="multiGroupField?.inputType === 'select' && multiGroupField?.multiRow" class="form-group w-100">
        <label class="form-label" [for]="multiGroupField.labelName">
          {{ multiGroupField.labelName }}
          <sup *ngIf="multiGroupField?.mandatory && mode !== 'visualizza'">*</sup>
        </label>
        <ng-select
          [items]="data ? data : multiGroupField?.data"
          [searchable]="true"
          [ngModel]="multiGroupField?.value"
          [multiple]="multiGroupField?.multiSelect"
          [closeOnSelect]="multiGroupField?.multiSelect ? false : true"
          [bindLabel]="multiGroupField?.bindedLabel ? multiGroupField?.bindedLabel : 'description'"
          bindValue="id"
          class="selectResearch"
          [ngClass]="{ 'single-control': !multiGroupField?.multiSelect }"
          (ngModelChange)="onInputMultiGroupChange($event, 'select', index, i, multiGroupField)"
          [disabled]="mode === 'visualizza' || isInputDisabled || multiGroupField?.readOnly"></ng-select>
        <div class="text-danger" *ngIf="multiGroupField?.mandatory && isInvalid">
          <div>Campo obbligatorio</div>
        </div>
      </div>

      <!-- input Date  -->
      <div *ngIf="multiGroupField?.inputType === 'date'" class="form-group">
        <label class="form-label" [for]="multiGroupField.labelName">
          {{ multiGroupField.labelName }}
          <sup *ngIf="multiGroupField?.mandatory && mode !== 'visualizza'">*</sup>
        </label>
        <input
          class="datepicker-here form-control digits"
          id="startDate"
          placeholder="gg-mm-aaaa"
          [value]="formattedDate"
          [markDisabled]="isDisabled"
          name="dp"
          ngbDatepicker
          #d="ngbDatepicker"
          (click)="d.toggle()"
          (dateSelect)="onDateChange($event)"
          (input)="onInputMultiGroupChange($event, 'date', index, i)"
          [disabled]="mode === 'visualizza'" />
        <div class="text-danger" *ngIf="multiGroupField?.mandatory && isInvalid">
          <div>Campo obbligatorio</div>
        </div>
      </div>

      <!-- input CheckBox  -->
      <div *ngIf="multiGroupField?.inputType === 'checkbox'" class="form-group">
        <label class="form-label" [for]="multiGroupField.labelName">
          {{ multiGroupField.labelName }}
          <sup *ngIf="multiGroupField?.mandatory && mode !== 'visualizza'">*</sup>
        </label>
        <div class="checkbox m-squar">
          <input
            [id]="multiGroupField?.id"
            type="checkbox"
            [checked]="multiGroupField?.value ? true : false"
            [value]="multiGroupField?.value ? true : false"
            (change)="onInputMultiGroupChange($event, 'checkbox', index, i)"
            [disabled]="mode === 'visualizza'" />
          <span class="checkmark"></span>
        </div>
        <div class="text-danger" *ngIf="multiGroupField?.mandatory && isInvalid">
          <div>Campo obbligatorio</div>
        </div>
      </div>

      <div style="margin-left: 10px; width: 30%" class="form-group" *ngIf="multiGroupField?.quantityField">
        <label class="form-label" [for]="'quantità'">Quantità</label>
        <div class="d-flex align-items-center justify-content-between gap-1">
          <button
            *ngIf="mode !== 'visualizza'"
            class="btn small-square-primary button-add-object"
            type="button"
            (click)="multiGroupField.quantity = multiGroupField.quantity > 0 ? multiGroupField.quantity - 1 : 0">
            <img style="height: auto; width: 20px; filter: invert()" src="../../../../assets/svg/toggle-row-minus.svg" alt="Meno" />
          </button>
          <input
            (input)="onInputMultiGroupChange($event, 'quantity', index, i)"
            class="form-control"
            [id]="multiGroupField?.id"
            [value]="multiGroupField?.quantity ? multiGroupField?.quantity : 0"
            [type]="multiGroupField?.dataType == 'Int' ? 'number' : 'text'"
            [disabled]="mode === 'visualizza'" />
          <button
            *ngIf="mode !== 'visualizza'"
            class="btn small-square-primary button-add-object"
            type="button"
            (click)="multiGroupField.quantity = multiGroupField.quantity + 1">
            <img style="height: auto; width: 20px; filter: invert()" src="../../../../assets/svg/toggle-row-plus.svg" alt="Aggiungi" />
          </button>
        </div>
      </div>

      <div class="d-flex gap-1">
        <button
          *ngIf="index > 0 && mode !== 'visualizza' && i + 1 === singleGroup.length"
          class="btn btn-small-plus small-delete-icon"
          type="button"
          (click)="removeMultiGroupRow(index)">
          <img src="../../../../assets/svg/trash-icon.svg" alt="Elimina" />
        </button>
        <button
          *ngIf="multiGroupData.length === index + 1 && mode !== 'visualizza' && i + 1 === singleGroup.length"
          class="btn btn-small-plus small-delete-icon"
          type="button"
          (click)="addMultiGroupRow()"
          style="margin-left: 5px">
          <img src="../../../../assets/svg/small-plus-icon.svg" alt="Aggiungi" />
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mode == 'visualizza' && multiGroupDataToShow.length > 0" class="row">
  <div *ngFor="let group of multiGroupDataToShow; let i = index" class="col-3 mt-3">
    <div *ngFor="let singleGroup of group; let j = index" class="">
      <div *ngIf="returnType(singleGroup.value) !== 'boolean'" class="form-group">
        <label class="form-label" [for]="singleGroup.labelName">
          {{ singleGroup.labelName }}
        </label>
        <input
          class="form-control"
          [id]="singleGroup?.labelName"
          [value]="singleGroup?.value ? singleGroup?.value : ''"
          type="text"
          [disabled]="mode === 'visualizza'" />
      </div>
      <div *ngIf="returnType(singleGroup.value) === 'boolean'" class="form-group">
        <label class="form-label" [for]="singleGroup.labelName">
          {{ singleGroup.labelName }}
        </label>
        <div class="checkbox m-squar">
          <input
            type="checkbox"
            [checked]="singleGroup?.value ? true : false"
            [value]="singleGroup?.value ? true : false"
            [disabled]="mode === 'visualizza'" />
          <span class="checkmark"></span>
        </div>
      </div>
    </div>
  </div>
</div>
