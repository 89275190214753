import { Component } from "@angular/core";
import "@angular/localize/init";
import { FirebaseService } from "./core/services/firebase.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "alfa-force-crm";

  constructor(private firebaseService: FirebaseService) {
    this.firebaseService.listenToMessages();
  }
}
