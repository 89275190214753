<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="wrap-select-tipology row justify-content-end mb-4 align-items-end">
                        <div class="col-auto">
                            <label class="form-label" for="type-tipology">Tipo Tipologica</label>
                            <select class="form-select" id="type-tipology" required (change)="onTypeChange($event)">
                                <option selected disabled value=""></option>
                                <option *ngFor="let item of dataTypeTypology"
                                        [value]="item.id" [defaultSelected]="item.id === idTipologySetected"
                                >{{ item.name }}</option>
                            </select>
                            <div class="invalid-feedback">Campo obbligatorio</div>
                        </div>
                        <div class="col-auto">
                            <button class="btn small-square-primary button-add-object"
                                    *ngIf="editable"
                                    ngbTooltip="Nuova tipologica" placement="top" tooltipClass="alfa-tooltip" container="body"
                                    (click)="modalManageTipology(staticContent, '', '')">
                                <svg
                                        fill="#ffffff"
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="64px"
                                        height="64px"
                                        viewBox="-9.08 -9.08 63.56 63.56"
                                        xml:space="preserve"
                                        stroke="#ffffff"
                                        stroke-width="0.00045402">
                                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                <g
                                                        id="SVGRepo_tracerCarrier"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke="#CCCCCC"
                                                        stroke-width="0.18160800000000002"></g>
                                                <g id="SVGRepo_iconCarrier">
                                    <g>
                                      <path
                                              d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141 c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27 c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435 c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"></path>
                                    </g>
                                  </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive theme-scrollbar">
                        <div class="dataTables_wrapper no-footer">
                            <table class="display dataTable no-footer" id="table-users">
                                <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Nome</th>
                                    <th>Azione</th>
                                </tr>
                                </thead>
                                <tbody>
                                    @for (item of dataTableTypology; track item) {
                                        <tr>
                                            <td>{{ item.typologyType?.name }}</td>
                                            <td>{{ item?.name }}</td>
                                            <td>
                                                <ul class="action" *ngIf="item.typologyType?.editable === true">
                                                    <li>
                                                        <div ngbDropdown class="d-inline-block">
                                                            <button type="button" class="btn"
                                                                    [id]="'dropdownBasic-' + item.id" ngbDropdownToggle>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="5"
                                                                     height="26.664" viewBox="0 0 5 26.664">
                                                                    <g id="Raggruppa_2011" data-name="Raggruppa 2011"
                                                                       transform="translate(3168.852 6027.439)">
                                                                        <path id="Tracciato_590" data-name="Tracciato 590"
                                                                              d="M19.625,17.125a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,19.625,17.125Z"
                                                                              transform="translate(-3183.477 -6031.232)" />
                                                                        <path id="Tracciato_592" data-name="Tracciato 592"
                                                                              d="M19.625,28.375a2.5,2.5,0,1,1-2.5-2.5,2.5,2.5,0,0,1,2.5,2.5Z"
                                                                              transform="translate(-3183.477 -6031.65)" />
                                                                        <path id="Tracciato_594" data-name="Tracciato 594"
                                                                              d="M19.625,5.875a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,19.625,5.875Z"
                                                                              transform="translate(-3183.477 -6030.814)" />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                            <div ngbDropdownMenu
                                                                 [attr.aria-labelledby]="'dropdownBasic-' + item.id">
                                                                <button ngbDropdownItem (click)="modalManageTipology(staticContent, item.id, item.name)">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 13px; margin-right: 10px">
                                                                        <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                                                        <path
                                                                                d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z" />
                                                                    </svg>
                                                                    Modifica
                                                                </button>
                                                                <button (click)="deleteTipology(item.id, item.name)" ngbDropdownItem>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="width: 13px; margin-right: 10px">
                                                                        <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                                                        <path
                                                                                d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z" />
                                                                    </svg>
                                                                    Elimina
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #staticContent let-modal>
    <div class="modal-body text-start">
        <button type="button" class="btn m-2 small-square-primary pull-right" (click)="close()">
            <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="-8.77 -8.77 59.30 59.30" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M27.948,20.878L40.291,8.536c1.953-1.953,1.953-5.119,0-7.071c-1.951-1.952-5.119-1.952-7.07,0L20.878,13.809L8.535,1.465 c-1.951-1.952-5.119-1.952-7.07,0c-1.953,1.953-1.953,5.119,0,7.071l12.342,12.342L1.465,33.22c-1.953,1.953-1.953,5.119,0,7.071 C2.44,41.268,3.721,41.755,5,41.755c1.278,0,2.56-0.487,3.535-1.464l12.343-12.342l12.343,12.343 c0.976,0.977,2.256,1.464,3.535,1.464s2.56-0.487,3.535-1.464c1.953-1.953,1.953-5.119,0-7.071L27.948,20.878z"></path> </g> </g></svg>
        </button>
        <div class="modal-body-content p-5">
            <div class="modal-toggle-wrapper">
                <h4 class="text-dark">
                    {{ modeModal === 'nuovo' ? 'Nuova Tipologia' : 'Modifica tipologia' }}
                </h4>
                <p>{{ modeModal === 'nuovo' ? 'Inserisci il nome della nuova ' : 'Modifica il nome della ' }} tipologia</p>
                <form #f="ngForm"
                      [formGroup]="typologyForm"
                      novalidate [ngClass]="{'was-validated': validateTypologyForm}"
                      (ngSubmit)="addOrUpdateTypology()"
                      class="theme-form">
                    <div class="content">
                        <div class="form-group mt-4">
                            <input class="form-control" formControlName="idTypeTypology" id="idTypeTypology" type="text" required="" hidden="">
                        </div>
                        <div class="form-group mt-4">
                            <label class="form-label" for="nameTypology">Nome <sup>*</sup></label>
                            <input class="form-control" formControlName="nameTypology" id="nameTypology" type="text" required="">
                            <div class="invalid-feedback">Campo obbligatorio</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-5">
                        <button type="submit" class="btn btn-primary btn-fill">Salva</button>
                        <div class="clearfix"></div>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-flex footer-sweetalert align-items-center overflow-hidden">
            <div class="col d-flex align-items-baseline justify-content-center">
                <img src="./../../../../assets/svg/alfa-force-logo.svg" alt="alfa force logo">
            </div>
            <div class="col bar-dx">
            </div>
        </div>
    </div>
</ng-template>