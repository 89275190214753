<!--<svg class="stroke-icon" *ngIf="layoutService.config.settings.icon === 'stroke-svg'; else fill">
  <use attr.xlink:href="assets/svg/icon-sprite.svg#stroke-{{icon}}"></use>
  <use attr.xlink:href="assets/svg/{{icon}}.svg"></use>
</svg>
<ng-template #fill>
  <svg class="fill-icon">
      <use attr.xlink:href="assets/svg/icon-sprite.svg#fill-{{icon}}"></use>
    </svg>
  </ng-template>-->

<img src="assets/svg/{{icon}}.svg" alt="" class="icon-sidebar">