// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://dev-alfaforce-api.dotitsrl.it/api/v1/",
  imagePath: "",
  home: "anagrafiche/utenti",
  firebase: {
    apiKey: "AIzaSyBofAe244PQMu26TmoXMU-HLpETTJQ0uCM",
    authDomain: "alfaforce-68b3c.firebaseapp.com",
    projectId: "alfaforce-68b3c",
    storageBucket: "alfaforce-68b3c.firebasestorage.app",
    messagingSenderId: "102051100675943837097",
    appId: "1:894262216361:android:90558ec577791e861fcbb8",
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
