import { Injectable, EventEmitter } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { BehaviorSubject, take } from "rxjs";
import firebase from "firebase/compat/app";
import { ToastrService } from "ngx-toastr";

import "firebase/compat/messaging";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";

interface PushNotification {
  notification: {
    title: string;
    body: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  private currentMessage = new BehaviorSubject<any>(null);
  private newPushNotification = new EventEmitter<PushNotification>();
  private app: Promise<firebase.app.App>;
  private serviceWorkerRegistration: Promise<ServiceWorkerRegistration> | null = null;

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private fireMessaging: AngularFireMessaging,
    private toastr: ToastrService
  ) {
    this.app = this.auth.app;
  }

  private async registerServiceWorker(): Promise<ServiceWorkerRegistration> {
    if (!this.serviceWorkerRegistration) {
      if ("serviceWorker" in navigator) {
        try {
          this.serviceWorkerRegistration = navigator.serviceWorker.register("/firebase-messaging-sw.js");
        } catch (error) {
          console.error("Service worker registration failed:", error);
          throw error;
        }
      } else {
        throw new Error("Service workers are not supported in this browser");
      }
    }
    return this.serviceWorkerRegistration;
  }

  async requestPermission(): Promise<string | null> {
    try {
      // Register service worker first
      await this.registerServiceWorker();

      return new Promise((resolve, reject) => {
        this.fireMessaging.requestToken.subscribe({
          next: (token) => {
            resolve(token);
          },
          error: (err) => {
            console.error("Failed to get FCM token:", err);
            reject(err);
          },
        });
      });
    } catch (error) {
      console.error("Failed to request permission:", error);
      return null;
    }
  }

  async addDocument(collectionName: string, data: any) {
    return this.firestore.collection(collectionName).add(data);
  }

  async getDocument(collectionName: string, documentId: string) {
    const doc = await this.firestore.collection(collectionName).doc(documentId).get().toPromise();
    return doc?.data();
  }

  async updateDocument(collectionName: string, documentId: string, data: any) {
    return this.firestore.collection(collectionName).doc(documentId).update(data);
  }

  async deleteDocument(collectionName: string, documentId: string) {
    return this.firestore.collection(collectionName).doc(documentId).delete();
  }

  async queryCollection(collectionName: string, field: string, operator: any, value: any) {
    const querySnapshot = await this.firestore
      .collection(collectionName, (ref) => ref.where(field, operator, value))
      .get()
      .toPromise();
    return (
      querySnapshot?.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Record<string, any>),
      })) || []
    );
  }

  async uploadFile(path: string, file: File) {
    const ref = this.storage.ref(path);
    const task = ref.put(file);
    await task;
    return ref.getDownloadURL().toPromise();
  }

  listenToMessages() {
    this.fireMessaging.messages.subscribe((message: any) => {
      const notification = message?.notification || message?.data;
      if (notification) {
        const title = notification.title || "Nuovo Messaggio";
        const body = notification.body || "";

        this.currentMessage.next(message);

        this.newPushNotification.emit({
          notification: {
            title,
            body,
          },
        });

        this.toastr.info(body, title, {
          timeOut: 20000,
          closeButton: true,
          tapToDismiss: true,
        });
      }
    });
  }

  getPushNotifications() {
    return this.newPushNotification.asObservable();
  }

  getCurrentMessage() {
    return this.currentMessage.asObservable();
  }
}
