import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService} from "../../pages/auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const expectedRole = 1 || 2;
        const userRole = this.authService.getRole();

        if (userRole === expectedRole) {
            return true;
        } else {
            this.router.navigate(['/sample-page']);
            return false;
        }
    }
}